import React, {memo, useCallback, useEffect, useMemo} from "react";
import {useSelector} from "react-redux";
import {useAppDispatch} from "store";
import {bookServiceRecord, getStaffRecords, getStaffRecordsByDate} from "store/service/thunk";
import {getParkingInfo} from "store/parking/thunk";
import {closeService, setError, setField, setTouched} from "store/service/slice";
import getQueryParams from "utils/getQueryParams";
import {validateName, validatePhone} from "pages/service/validate";
import Input from "components/Input";
import {LOG_ERRORS, LOG_TYPES} from "utils/consts";
import {log} from "utils/analytics";
import {SERVICE_TYPE_TEXT, STEPS} from "pages/service/consts";
import {ServiceCard} from "pages/service/views/components/ServiceCard";
import useInterval from "utils/hooks/useInterval";
import {Button} from "components/Button";
import {BTN_COLOR} from "components/Button/consts";

import styles from "./styles.module.scss";

import type {RootState} from "store";

export const Washing: React.FC = memo(() => {
  const {place, id} = useMemo(() => getQueryParams(), []);
  const dispatch = useAppDispatch();

  const {
    parking: {
      parkingInfo
    },
    service: {
      serviceInfo,
      category,
      error,
      touched,
      field,
      step,
      isAskedNextDay,
      submitting
    }
  } = useSelector((s: RootState) => s);

  const delay = step === STEPS.LAST ? 10_000 : null;
  const isValid = !error['phone'] && !error['name'] && touched['phone'] && touched['name'];

  useInterval(() => {
    if (place && id && step === STEPS.LAST) {
      dispatch(getParkingInfo({place, id}));
    } else {
      return;
    }
  }, delay);

  useEffect(() => {
    if (parkingInfo?.order_yc?.[0]?.status === 2) {
      dispatch(closeService());
    }
  }, [parkingInfo]);

  useEffect(() => {
    if (step === STEPS.INITIAL) {
      dispatch(getStaffRecords());

      log(LOG_TYPES.SERVICE_PAGE_ORDER_OPEN, parkingInfo, {
        ServiceType: category && SERVICE_TYPE_TEXT[category],
        ServiceName: serviceInfo?.booking_title,
        ServicePrice: serviceInfo?.price_min
      });

      // Обновляем данные, были ошибки когда оставалась предыдущая сессия
      if (place && id) {
        dispatch(getParkingInfo({place, id}));
      }
    } else {
      log(LOG_TYPES.SERVICE_PAGE_ORDER_SUCCESS_OPEN, parkingInfo, {
        ServiceType: category && SERVICE_TYPE_TEXT[category],
        ServiceName: serviceInfo?.booking_title,
        ServicePrice: serviceInfo?.price_min,
        Name: field['name'],
        Phone: field['phone'],
        YCBookTime: serviceInfo?.time || "",
        YCBookID: serviceInfo?.id || ""
      });
    }
  }, [step]);

  useEffect(() => {
    if (isAskedNextDay) {
      dispatch(getStaffRecordsByDate());
    }
  }, [isAskedNextDay]);

  const handleChangeName = useCallback((val: string) => {
    const err = validateName(val);
    if (!err) {
      dispatch(setError({field: 'name', value: null}));
    }
    if (!touched['name']) {
      dispatch(setTouched({field: 'name', value: true}));
    }
    dispatch(setField({field: 'name', value: val}));
  }, []);

  const handleBlurName = useCallback((val: string) => {
    const err = validateName(val);
    dispatch(setError({field: 'name', value: err}));
    dispatch(setTouched({field: 'name', value: true}));

    log(LOG_TYPES.INPUT_FILLED, parkingInfo, {
      ServiceName: serviceInfo?.booking_title,
      ServicePrice: serviceInfo?.price_min,
      InputData: val
    });
  }, []);

  const handleChangePhone = useCallback((val: string) => {

    const err = validatePhone(val);
    if (!err) {
      dispatch(setError({field: 'phone', value: null}));
    }
    if (!touched['phone']) {
      dispatch(setTouched({field: 'phone', value: true}));
    }
    dispatch(setField({field: 'phone', value: val}));
  }, []);

  const handleBlurPhone = useCallback((val: string) => {
    const err = validatePhone(val);
    dispatch(setError({field: 'phone', value: err}));
    dispatch(setTouched({field: 'phone', value: true}));

    log(LOG_TYPES.INPUT_FILLED, parkingInfo, {
      ServiceName: serviceInfo?.booking_title,
      ServicePrice: serviceInfo?.price_min,
      InputData: val
    });
  },[]);

  const handleFocusField = useCallback(() => {
    log(LOG_TYPES.INPUT_ACTIVATED, parkingInfo, {
      ServiceName: serviceInfo?.booking_title,
      ServicePrice: serviceInfo?.price_min
    });
  },[]);

  const handleNextStep =  useCallback(async() => {
    if (!submitting) {
      log(LOG_TYPES.BTN_SERVICE_SUBMIT, parkingInfo, {
        ServiceName: serviceInfo?.booking_title,
        ServicePrice: serviceInfo?.price_min,
        Name: field['name'],
        Phone: field['phone']
      });

      if (error['phone'] || error['name'] || !touched['phone'] || !touched['name']) {
        log(LOG_ERRORS.CREATE_SERVICE_ORDER, parkingInfo, {ErrorDescription: 'Неверно заполнена форма'});
        return;
      }

      dispatch(bookServiceRecord());
    }
  }, [error, field, touched, serviceInfo, submitting]);

  let content;

  if (step === STEPS.INITIAL) {
    content = (
      <>
        <ServiceCard service={serviceInfo} />
        <div className={styles.contacts}>
          <div className={styles.title}>Контактные данные (обязательно):</div>
          <div className={styles.comment}>C вами свяжется оператор мойки и подтвердит заказ.</div>
          <Input
            inputMode="text"
            name="name"
            placeholder="Ваше имя"
            value={field['name'] || ''}
            onChange={handleChangeName}
            onBlur={handleBlurName}
            onFocus={handleFocusField}
            error={error['name']}
          />
          <Input
            inputMode="tel"
            name="phone"
            placeholder="+7 (123) 456-78-90"
            value={field['phone'] || ''}
            onChange={handleChangePhone}
            onBlur={handleBlurPhone}
            onFocus={handleFocusField}
            error={error['phone']}
          />
        </div>
      </>
    );
  } else {
    content = (
      <>
        <ServiceCard service={serviceInfo} />
        <div className={styles.client}>
          <div className={styles.row}>
            <div>Имя :</div>
            <div>{field['name']}</div>
          </div>
          <div className={styles.row}>
            <div>Номер авто :</div>
            <div>{parkingInfo?.car_number}</div>
          </div>
          <div className={styles.row}>
            <div>Ваш телефон :</div>
            <div>{field['phone']}</div>
          </div>
        </div>
        <div>
          <div className={styles.subTitle}>Спасибо, услуга заказана!</div>
          <div className={styles.subText}>Скоро с вами свяжется оператор, чтобы подтвердить заказ. Можно вернуться на главный экран.</div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.container}>
        {content}
      </div>
      {step === STEPS.INITIAL && (
        <div className={styles.btn} data-valid={isValid && !submitting}>
          <Button color={BTN_COLOR.GREEN_STICKY} onClick={handleNextStep}>Заказать услугу</Button>
        </div>
      )}
    </>
  );
});
