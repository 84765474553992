import React from "react";
import CartProductList from "../CartProductList";

const Cart: React.FC = () => {
  return (
    <CartProductList />
  );
};

export default Cart;
