import {useSelector} from "react-redux";

import type {RootState} from "store";

import {clsx} from "clsx";
import styles from "./styles.module.scss";
import sharedStyles from "../../styles.module.scss";

const StatusProductList = () => {
  const model = useSelector((s: RootState) => s.orderStatus.payment);

  if (!model?.products?.length) {return null;}

  return (
    <div className={styles.wrapper}>
      <div className={clsx(sharedStyles.title, styles.cardTitle)}>Состав заказа</div>
      <div className={styles.productsList}>
        {model.products.map(it => (
          <div className={styles.product} key={it.id}>
            <div className={styles.productTitle}>{it.product.title}</div>
            <div className={styles.productInfo}>
              <div className={styles.productCost}>{it.product.totalCost}</div>
              <div className={styles.productQuantity}>{it.quantity} шт.</div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.invoice}>
        <div className={styles.invoiceAmount}>
          <div>Итого:</div>
          <div>{model.invoice.amount}</div>
        </div>
        {model.invoice.discountAmount && <div className={styles.invoiceDiscount}>
          <div>Скидка:</div>
          <div>{model.invoice.discountAmount}</div>
        </div>}
      </div>
    </div>
  );
};

export default StatusProductList;
