import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {useAppDispatch} from "store";
import PickedProducts from "../CartProductList";
import PaymentInfo from "./PaymentInfo";
import PaymentMethods from "./PaymentMethods";
import {Loader} from "components/Loader";
import {getCart} from "store/market/thunk";

import styles from "./styles.module.scss";

import type {RootState} from "store";

const Payment: React.FC = () => {
  const loaded = useSelector((state: RootState) => state.market.loadedCartPayment);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCart({check: true}));
  }, []);

  if (!loaded) {
    return <Loader />;
  }

  return (
    <div className={styles.payment}>
      <PickedProducts isPayment>
        <PaymentInfo />
        <PaymentMethods />
      </PickedProducts>
    </div>
  );
};

export default Payment;
