import React            from 'react';
import {createRoot}     from 'react-dom/client';
import {Provider}       from 'react-redux';
import {HistoryRouter} 	from "redux-first-history/rr6";
import {
  store, browserHistory
}                       from 'store';
import {Router}         from "./pages/router";

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <Provider store={store}>
      <HistoryRouter history={browserHistory}>
        <Router />
      </HistoryRouter>
    </Provider>
);
