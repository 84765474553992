import type {MarketState} from "store/market/slice";

export const validate = (values: MarketState): Dictionary<string | null> => {
  const err: Dictionary<string | null> = {};

  if (!values.name?.length) {
    err.name = 'Обязательное поле';
  }
  if (!values.phone?.length) {
    err.phone = 'Обязательное поле';
  }
  if (!values.agreement) {
    err.agreement = 'Обязательное поле';
  }

  return err;
};
