import React from "react";
import {GV_BILLING} from "../../../consts";

import {clsx} from "clsx";
import styles from "./styles.module.scss";

interface PaymentMethodProps {
  method: {name: string; slug: string;};
  active: string | null;
  onChange: (method: string) => void;
}

const icons = (
  <div className={styles.paymentMethodsImages}>
    <div className={styles.sbp} />
    <div className={styles.visa} />
    <div className={styles.mc} />
    <div className={styles.mir} />
  </div>
);

export const PaymentMethod: React.FC<PaymentMethodProps> = ({method, onChange, active}) => {
  return (
    <div className={styles.paymentMethod} onClick={() => onChange(method.slug)}>
      <div className={clsx(styles.paymentMethodBtn, {[styles.paymentMethodBtnActive]: method.slug === active})} />
      {method.name}
      {method.slug === GV_BILLING && icons}
    </div>
  );
};
