export default function groupBy(iterable, callback) {
  const groups = {};
  for (const item of iterable) {
    const result = callback(item);
    if (!groups[result]) {
      groups[result] = [item];
    } else {
      groups[result].push(item);
    }
  }
  return groups;
}
