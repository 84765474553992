import debounce from "lodash.debounce";
import {set} from "utils/redux/actions";
import {PREFIX} from "store/market/consts";
import {changeRequest, addPendingRequest, clearPendingRequest} from "store/market/slice";
import {addProduct, deleteProduct} from "store/market/thunk";
import {getRequestKey} from "./helpers";

import type {AppDispatch, RootState} from "store";

export const debouncedAddProduct = (productId: string | number, categoryId: string | number, amount = 1) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(set(PREFIX, {loadedCart: false}));

    const key = getRequestKey(categoryId, productId);
    dispatch(changeRequest({key, amount}));
    executeDebouncedAddProduct(productId, categoryId, dispatch, getState);
  };

export const debouncedRemoveProduct = (productId: string | number, categoryId: string | number, amount = 1) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(set(PREFIX, {loadedCart: false}));

    const cartItem = getState()?.market?.cart?.products?.find(it => it.product.categories?.[0]?.id === categoryId && it.product.id === productId);
    if (cartItem?.quantity === 0) {return;}

    const key = getRequestKey(categoryId, productId);
    dispatch(changeRequest({key, amount}));
    executeDebouncedRemoveProduct(productId, categoryId, dispatch, getState);
  };


const executeDebouncedAddProduct = debounce(
  (productId: string | number, categoryId: string | number, dispatch: AppDispatch, getState: () => RootState) => {
    const request = getState().market.pendingRequest;
    const key = getRequestKey(categoryId, productId);

    if (!request || (request && request.key !== key)) {
      dispatch(set(PREFIX, {loadedCart: true}));
      return;
    }

    dispatch(addProduct({productId, categoryId, quantity: request.amount}));
  }, 500, {leading: false, trailing: true}
);

const executeDebouncedRemoveProduct = debounce(
  (productId: string | number, categoryId: string | number, dispatch: AppDispatch, getState: () => RootState) => {
    const request = getState().market.pendingRequest;
    const key = getRequestKey(categoryId, productId);

    if (!request || request && (request.key !== key || !request.amount)) {
      dispatch(set(PREFIX, {loadedCart: true}));
      return;
    }

    dispatch(deleteProduct({productId, categoryId, quantity: request.amount}));
  }, 500, {leading: false, trailing: true}
);
