import React from "react";
import {useNavigate} from "react-router";
import {useAppDispatch} from "store";
import {Icon} from "components/Icon";
import {PRODUCT_TYPE} from "pages/market/consts";
import {getProductBottomTitle} from "./helpers";
import {debouncedAddProduct, debouncedRemoveProduct} from "pages/market/actions";

import {clsx} from "clsx";
import styles from "./styles.module.scss";

interface ProductBottomProps {
  productId: number;
  categoryId: number;
  productType: string;
  cartAmount: number;
  loadedCart?: boolean;
}

const ProductBottom: React.FC<ProductBottomProps> = React.memo((props) => {
  const [amount, setAmount] = React.useState<number>(props.cartAmount || 1);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {productId, categoryId, productType, cartAmount, loadedCart} = props;
  const isService = productType === PRODUCT_TYPE.SERVICES;
  const disabledAdd = (isService && amount === 1);
  const disabledRemove = (amount === 1 && cartAmount === 0) || amount === 0;
  const buttonTitle = getProductBottomTitle(cartAmount, amount);

  const addAmount = React.useCallback(() => {
    if (disabledAdd) {return;}
    setAmount(prev => prev + 1);
  }, [disabledAdd]);

  const removeAmount = React.useCallback(() => {
    if (disabledRemove) {return;}
    setAmount(prev => prev - 1);
  }, [disabledRemove]);

  const onChange = React.useCallback(() => {
    if (!loadedCart) {return;}
    if (amount === cartAmount) {
      navigate(-1);
    }
    if (amount > cartAmount) {
      dispatch(debouncedAddProduct(productId, categoryId, amount - cartAmount));
    }
    if (amount < cartAmount) {
      dispatch(debouncedRemoveProduct(productId, categoryId, cartAmount - amount));
    }
  }, [navigate, amount, cartAmount, loadedCart]);

  return (
    <div className={styles.productBottom}>
      <div className={styles.productBottomCounter}>
        <div className={clsx(styles.productBottomButton, {[styles.productBottomButtonDisabled]: disabledRemove})} onClick={removeAmount}>
          <Icon.Minus/>
        </div>
        <div className={styles.productBottomAmount}>{amount}</div>
        <div className={clsx(styles.productBottomButton, {[styles.productBottomButtonDisabled]: disabledAdd})} onClick={addAmount}>
          <Icon.Plus/>
        </div>
      </div>
      <div className={clsx(styles.productBottomAction, {
        [styles.productBottomActionDelete]: amount === 0 && cartAmount > 0,
        [styles.productBottomActionDisabled]: !loadedCart
      })} onClick={onChange}>{buttonTitle}</div>
    </div>
  );
});

export default ProductBottom;
