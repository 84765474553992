import React from "react";

import styles from './styles.module.scss';

interface InfoCardProps {
  startTime: string;
  parkingTime: string | null;
  valetCard: string;
  place: string;
  image: string;
  model: string;
  number: string;
  tariff?: string;
  paid?: string;
}

const renderOption = (label: string, value?: string | number) => {
  if (!value) {return null;}
  return (
    <div className={styles.option}>
      <dt>{label}</dt>
      <dd>{value}</dd>
    </div>
  );
};

export const InfoCard: React.FC<InfoCardProps> = ({
  startTime,
  parkingTime,
  valetCard,
  place,
  image,
  model,
  number,
  tariff,
  paid
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <div className={styles.image}>
          <img src={image} alt="car"/>
        </div>
        <div className={styles.block}>
          <div className={styles.model}>{model}</div>
          <div className={styles.number}>{number}</div>
        </div>
      </div>
      <dl>
        {renderOption('Время приёма авто:', startTime)}
        {renderOption('Время на парковке:', parkingTime || 'Не указано')}
        {renderOption('Номер Valet-карты', valetCard)}
        {renderOption('Место на парковке:', place)}
        {tariff && renderOption('Тариф:', tariff)}
        {paid && renderOption('Оплачено:', `${paid}₽`)}
      </dl>
    </div>
  );
};
