import React, {useCallback} from "react";
import {push} from "redux-first-history";
import {useParams} from "react-router";
import {useAppDispatch} from "store";

import {clsx} from "clsx";
import styles from "./styles.module.scss";


interface CartBottomProps {
  loadedCart: boolean;
  isEmpty: boolean;
}

const CartBottom: React.FC<CartBottomProps> = ({loadedCart, isEmpty}) => {
  const {slug} = useParams();
  const dispatch = useAppDispatch();

  const openConfirm = useCallback(() => {
    if (!loadedCart && !slug) {return;}
    dispatch(push(`/${slug}/confirmation`));
  }, [dispatch, slug, loadedCart]);

  if (isEmpty) {return null;}

  return (
    <div className={clsx(styles.innerText, {[styles.innerTextLoading]: !loadedCart})} onClick={openConfirm}>
      Перейти к оформлению
    </div>
  );
};

export default CartBottom;
