import React, {memo} from "react";

import styles from './styles.module.scss';

export interface Template {
  title: string;
  text?: string;
  list?: string[];
  innerList?: (string | JSX.Element)[];
}

interface Props {
  template: Template[];
}

export const Description: React.FC<Props> = memo(({template}) => {
  return (
    <>
      {
        template.map(({title, text, list, innerList}, i) => {
          let listComponent, innerListComponent;

          if (list?.length) {
            listComponent = (
              <ol className={styles.list}>
                {list.map((value: string, j: number) => (
                  <li key={`${i}_${j}`}>{value}</li>
                ))}
              </ol>
            );
          }

          if (innerList?.length) {
            innerListComponent = (
              <ul className={styles.innerList}>
                {innerList.map((value, j) => {
                  if (typeof value === 'string') {
                    return (
                      <li key={`${i}_${j}`} dangerouslySetInnerHTML={{ __html: value }} />
                    );
                  } else {
                    return <li key={`${i}_${j}`}>{value}</li>;
                  }
                })}
              </ul>
            );
          }

          return (
            <div key={i} className={styles.row}>
              <p className={styles.title}>{title}</p>
              <p className={styles.text}>{text}</p>
              {listComponent}
              {innerListComponent}
            </div>
          );
        })
      }
    </>
  );
});
