import {createAsyncThunk}         from '@reduxjs/toolkit';
import * as service               from "services/market";
import {
  compareAndUpdateSid,
  getSidFromLs
} from "pages/market/helpers";
import {
  getFormattedCart,
  getFormattedCategory,
  getFormattedObject,
  getFormattedProduct
}                                 from "pages/market/formatData";
import {PREFIX}                   from "./consts";
import {setItem}                  from "utils/local-storage";
import LS_KEY                     from "utils/local-storage/consts";
import {set}                      from "utils/redux/actions";
import {validate}                 from "pages/market/validate";
import {clearRequest}             from "./slice";

import type {RootState}           from "store";

export const initMarket = createAsyncThunk(
  `${PREFIX}/init`,
  async ({slug, hasCart}: {slug?: string; hasCart: boolean} , {dispatch}) => {
    await dispatch(getObjectInfo({slug: slug as string, hasCart}));

    // if (category) {
    //   dispatch(getCategoryInfo(category));
    // }
    // if (product) {
    //   dispatch(getProductInfo(product));
    // }

    // if (category && !product) {
    //   dispatch(setScreen(MARKET_SCREEN.CATEGORY_LIST));
    // } else if (category && product) {
    //   dispatch(setScreen(MARKET_SCREEN.PRODUCT));
    // }
  }
);

export const getObjectInfo = createAsyncThunk(
  `${PREFIX}/object`,
  async ({slug, hasCart}: {slug: string, hasCart: boolean}, {rejectWithValue, dispatch}) => {
    try {
      const res = await service.getObject(slug);
      const data = getFormattedObject(res.data);

      const sid = getSidFromLs(data?.marketId);
      if (hasCart && sid) {
        await dispatch(getCart({sid, check: false, marketId: data?.marketId}));
      }

      return {sid, ...data};
    } catch (e) {
      const err = e as Error;
      return rejectWithValue(err.response.data.code);
    }
  }
);

export const getCategoryInfo = createAsyncThunk(
  `${PREFIX}/category`,
  async (id: number | string, {rejectWithValue}) => {
    try {
      const res = await service.getCategory(id);
      return getFormattedCategory(res.data);
    } catch (e) {
      const err = e as Error;
      return rejectWithValue(err.response.data.code);
    }
  }
);

export const getProductInfo = createAsyncThunk(
  `${PREFIX}/product`,
  async (id: number | string, {rejectWithValue}) => {
    try {
      const res = await service.getProduct(id);
      return getFormattedProduct(res.data);
    } catch (e) {
      const err = e as Error;
      return rejectWithValue(err.response.data.code);
    }
  }
);

export const addProduct = createAsyncThunk(
  `${PREFIX}/add`,
  async (
    {productId, categoryId, quantity}: {productId: string | number, categoryId: string | number, quantity?: number},
    {rejectWithValue, getState, dispatch}
  ) => {
    const model = getState() as RootState;
    const {marketId, sid} = model[PREFIX];

    if (!productId || !categoryId || !marketId) {
      return rejectWithValue('Недостаточно данных');
    }

    try {
      const res = await service.addProduct(productId, categoryId, marketId, quantity || 1, sid);
      dispatch(clearRequest());

      if (!sid) {
        setItem(`${LS_KEY.MARKET}${marketId}`, res.data.result.sid);
      }

      return getFormattedCart(res.data);
    } catch (e) {
      const err = e as Error;
      return rejectWithValue(err.response.data.code);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  `${PREFIX}/remove`,
  async (
    {productId, categoryId, quantity}: {productId: string | number, categoryId: string | number, quantity?: number},
    {rejectWithValue, getState, dispatch}
  ) => {
    const model = getState() as RootState;
    const {marketId, sid} = model[PREFIX];

    if (!productId || !categoryId || !marketId || !sid) {
      return rejectWithValue('Недостаточно данных');
    }

    try {
      const res = await service.deleteProduct(productId, categoryId, marketId, quantity || 1, sid);
      dispatch(clearRequest());

      const data = getFormattedCart(res.data);
      compareAndUpdateSid(marketId, data.sid, dispatch);

      return data;
    } catch (e) {
      const err = e as Error;
      return rejectWithValue(err.response.data.code);
    }
  }
);

export const deleteItemProduct = createAsyncThunk(
  `${PREFIX}/deleteItem`,
  async (
    {productId, categoryId}: {productId: string | number, categoryId: string | number},
    {rejectWithValue, getState, dispatch}
  ) => {
    const model = getState() as RootState;
    const {marketId, sid, cart} = model[PREFIX];
    const quantity = cart?.products?.find(({product}) =>
      product.id === productId && product.categories?.[0].id === categoryId
    )?.quantity;

    if (!productId || !categoryId || !marketId || !sid) {
      return rejectWithValue('Недостаточно данных');
    }

    try {
      const res = await service.deleteProduct(productId, categoryId, marketId, quantity || 1, sid);
      const data = getFormattedCart(res.data);

      compareAndUpdateSid(marketId, data.sid, dispatch);

      return data;
    } catch (e) {
      const err = e as Error;
      return rejectWithValue(err.response.data.code);
    }
  }
);

export const getCart = createAsyncThunk(
  `${PREFIX}/cart`,
  async (
    {sid, check, marketId}: {sid?: string, check?: boolean, marketId?: number},
    {rejectWithValue, getState, dispatch}
  ) => {
    const model = getState() as RootState;
    const marketIdValue = model[PREFIX]?.marketId || marketId;

    if (!marketIdValue) {
      return rejectWithValue('Нет marketId');
    }

    const sidValue = getSidFromLs(marketIdValue) || sid;

    if (!sidValue) {
      return rejectWithValue('Нет sid');
    }

    try {
      const res = await service.getCart(sidValue, check);
      const data = getFormattedCart(res.data);

      const paymentMethods = data.paymentInfo?.paymentMethods;
      if (check && paymentMethods && Object.values(paymentMethods).length === 1) {
        const paymentMethod = Object.values(paymentMethods)[0].slug;
        dispatch(set(PREFIX, {paymentMethod}));
      }

      compareAndUpdateSid(marketIdValue, data.sid, dispatch);

      return data;
    } catch (e) {
      const err = e as Error;
      return rejectWithValue(err.response.data.code);
    }
  }
);

export const clearCart = createAsyncThunk(
  `${PREFIX}/clear`,
  async (_, {rejectWithValue, getState}) => {
    const model = getState() as RootState;
    const {marketId, sid} = model[PREFIX];

    if (!sid) {
      return rejectWithValue('Нет sid');
    }

    try {
      await service.clearCart(sid, marketId);
    } catch (e) {
      const err = e as Error;
      return rejectWithValue(err.response.data.code);
    }
  }
);

export const startPayment = createAsyncThunk(
  `${PREFIX}/payment`,
  async (_, {rejectWithValue, getState, dispatch}) => {
    const model = getState() as RootState;

    const err = validate(model.market);
    dispatch(set(PREFIX, {errors: {...err}}));
    if (Object.values(err).length) {
      return rejectWithValue('Ошибка валидации');
    }

    if (!model.market?.sid) {
      return rejectWithValue('Нет sid');
    }
    if (!model.market.paymentMethod) {
      return rejectWithValue('Нет paymentMethod');
    }
    const paymentType = model.market?.cart?.paymentInfo?.paymentType?.slug;
    if (!paymentType) {
      return rejectWithValue('Нет paymentType');
    }

    try {
      const res = await service.initPayment(
        model.market.sid,
        paymentType,
        model.market.paymentMethod,
        model.market.name as string,
        model.market.phone?.replace(/[^+\d]/g, '')
      );

      const paymentUrl = res.data?.payment_data?.init_url;
      if (paymentUrl) {
        window.location.href = paymentUrl;
      } else {
        const pathname = model.router.location!.pathname;
        const [, slug] = pathname.split("/");
        const path = `/${slug}/status?orderId=${res.data?.order_data?.order_id}`;
        window.location.href = window.location.origin + path;
      }
    } catch (e) {
      const err = e as Error;
      return rejectWithValue(err?.response?.data?.code);
    }
  }
);
