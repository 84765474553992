import debounce from "lodash.debounce";
import type {BaseState} from "./Model";
import type {ThunkAction} from "redux-thunk";
import type {AnyAction, Dispatch} from "redux";

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export type GetState = () => any;

export type HandleChangeAction<State extends BaseState> = (field: string, value: unknown, touchField?: boolean)
	=> ThunkAction<void, State, unknown, AnyAction>;

export type HandleBlurAction<State extends BaseState> = (field: string, value: unknown)
	=> ThunkAction<void, State, unknown, AnyAction>;

export const handleChange =
	<State extends BaseState>(
		prefix: string,
		getModel: (getState: GetState) => State,
		validate: (model?, params?) => unknown,
		before?: (field: string, value: unknown, dispatch: Dispatch, getState?: GetState) => (boolean | void | unknown),
		after?: (field: string, value: unknown, dispatch: Dispatch, getState?: GetState) => void): HandleBlurAction<State> =>
		(field: string, value: unknown, allowObjectPath?: boolean, preventValidation?: boolean) => {
			return (dispatch: Dispatch, getState: GetState): void => {
				if (!prefix) {
					throw new Error('No prefix');
				}
				if (!getModel) {
					throw new Error('No getModel');
				}

				before && before(field, value, dispatch, getState);

				const payload = {field: field, value: value};
				dispatch({type: prefix + '/HANDLE_CHANGE', payload});

				const touched = getModel(getState).touched?.[field];
				if (!touched) {
					const plTouched = {field: field, value: true};
					dispatch({type: prefix + '/HANDLE_TOUCH', payload: plTouched});
				}

				void onChangeImpl(field, value, dispatch, getState, prefix, getModel, preventValidation, validate);

				after && after(field, value, dispatch, getState);
			};
		};

export const handleBlur = <State extends BaseState>(
	prefix: string,
	getModel: (getState: GetState) => State,
	before?: (field: string, value: unknown, dispatch: Dispatch, getState: GetState) => (boolean | { notChange: boolean }),
	after?: (field: string, value: unknown, dispatch: Dispatch, getState: GetState) => void): HandleBlurAction<State> =>
	(field: string, value: unknown) => {
		return (dispatch: Dispatch, getState: GetState): void => {
			if (!prefix) {
				throw new Error('No prefix');
			}
			if (!getModel) {
				throw new Error('No getModel');
			}

			before && before(field, value, dispatch, getState);

			const model = getModel(getState);
			const touched = {...model.touched};

			touched[field] = true;
			dispatch({type: prefix + '/HANDLE_TOUCH', touched, field, value});

			after && after(field, value, dispatch, getState);
		};
	};

export const touch =
	<T extends BaseState>(prefix: string) =>
		(model: T, fields: string[], dispatch: Dispatch): void => {
			const touched = {
				...model.touched
			};

			for (const f of fields) {
				touched[f] = true;
			}
			dispatch({type: prefix + '/HANDLE_TOUCH', touched});
		};


export const handleErrors = (prefix: string, payload: Record<string, string | null>) => {
	return (dispatch: Dispatch): void => {
		dispatch({type: prefix + '/SET_ERRORS', payload});
	};
};

export const handleError = (prefix: string, payload: Record<string, string | null>) => {
	return (dispatch: Dispatch): void => {
		dispatch({type: prefix + '/HANDLE_ERROR', payload});
	};
};

export const set = (prefix: string, payload: Record<string, unknown>) => {
	return (dispatch: Dispatch): void => {
		dispatch({type: prefix + '/SET_STATE', payload});
	};
};

export const reset = (prefix: string) => {
	return (dispatch: Dispatch): void => {
		dispatch({type: prefix + '/RESET_STATE'});
	};
};

export const validate = <T>(prefix: string, validation: (model: T) => Dictionary<unknown>) => {
	return (dispatch: Dispatch, getState: GetState): void => {
		const model = getState() as T;
		const res = validation(model);
		dispatch({type: prefix + '/VALIDATE', res});
	};
};

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const getOwnState = <T extends BaseState, T2 = any>(prefix: string) =>
	(getState: () => T2): T => {
		return getState()[prefix];
	};

export const onChangeImpl = debounce((field, value, dispatch, getState, prefix, getModel, preventValidation, validate) => {
	const model = getModel(getState);

	if (!model) {
		return;
	}

	const touched = model.touched?.[field];

	if (touched && !preventValidation && validate) {
		const values = {...model, [field]: value};
		const vr = validate(values)?.[field];
		dispatch({type: prefix + '/VALIDATE', payload: {[field]: vr}});
	}

}, 100);

