import React, {useEffect} from "react";
import {useAppDispatch}   from "store";
import {sendBookRequest}  from "store/parking/thunk";
import {getParking}       from "services/parking";
import {log}              from "utils/analytics";
import {DATE_FORMAT, LOG_TYPES} from "utils/consts";
import {getDateNow}       from "utils/date";

import {clsx}             from "clsx";
import styles             from "./styles.module.scss";

interface Props {
  isError: boolean;
  id: string | null;
  place: string | null
}

export const StatusPage: React.FC<Props> = ({isError, id, place}) => {
  const dispatch = useAppDispatch();
  const label = isError ? (
    <>
      Оплата не прошла. <br /> Загружаем данные сессии...
    </>
  ) : (
    <>
      Оплата прошла успешно! <br /> Загружаем данные сессии...
    </>
  );

  useEffect(() => {
    if (!isError && place && id) {
      getParking({place, id}).then(res => {
        const info = res?.data;

        log(LOG_TYPES.SESSION_CAR_REQUEST, info, {
          CarDeliveryTime: getDateNow().format(DATE_FORMAT)
        });

        dispatch(sendBookRequest({id, pvs: info?.id, parking: info?.parking?.id}));
      });
    }

    log(LOG_TYPES.PAYMENT_CALLBACK_STATUS, null, {Status: !isError});

    const timeout = setTimeout(() => {
      window.history.pushState({}, document.title, `/?P=${place}&VCID=${id}`);
      window.location.reload();
    }, 1500);
    return () => {
      clearTimeout(timeout);
    };
  }, [isError]);

  return (
    <div className={styles.page}>
      <div className={styles.block}>
        <div className={clsx(styles.progress, {
          [styles.progressOk]: !isError,
          [styles.progressError]: isError
        })}>
          <div className={clsx(styles.icon, {
            [styles.ok]: !isError,
            [styles.fail]: isError
          })}></div>
        </div>
        <div className={styles.label}>{label}</div>
      </div>
    </div>
  );
};
