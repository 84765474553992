import React, {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {useLocation, useParams} from "react-router";
import {Top} from "components/Top";
import {Header} from "../Header";
import Bottom from "../Bottom";
import SupportContacts from "../SupportContancts";
import {getImage, getMarketScreen, getOrderStatusParams} from "pages/market/helpers";
import {getBottomType} from "../Bottom/helpers";
import {onScrollTop} from "utils/onScrollTop";

import {clsx} from "clsx";
import styles from "./styles.module.scss";

import type {RootState} from "store";

interface MarketLayoutProps {
  children?: React.ReactElement | React.ReactElement[];
}

const MarketLayout: React.FC<MarketLayoutProps> = ({children}) => {
  const urlParams = useParams();
  const {pathname} = useLocation();
  const [params] = useSearchParams();
  const {
    image: mainImage, cart,
    sections, category, product, objectName, fullAddress,
    loadedProduct, loadedCategory, loaded
  } = useSelector((state: RootState) => state.market);
  const orderStatusHasBottom = useSelector((state: RootState) => state.orderStatus.hasBottomButton);

  const [isStartedAnimation, setIsStartedAnimation] = useState(() => urlParams?.slug && !urlParams?.categoryId && !urlParams?.productId);

  const screen = useMemo(() => getMarketScreen(pathname, urlParams), [pathname, urlParams]);
  const image = useMemo(() => getImage(screen, mainImage, category?.coverImage), [screen, mainImage, category?.coverImage]);
  const bottomType = useMemo(() => getBottomType(screen, cart, loadedProduct), [screen, cart, loadedProduct]);
  const {paymentSucceeded, paymentFailed} = getOrderStatusParams(params);
  const textParams = {
    fullAddress, objectName,
    categoryTitle: category?.title, productTitle: product?.title,
    sectionTitle: sections?.[0]?.title,
    paymentSucceeded, paymentFailed
  };
  const isLoadingHeader = !loadedProduct || !loadedCategory || !loaded;

  useEffect(() => {
    if (isStartedAnimation) {setIsStartedAnimation(false);}
  }, []);

  useEffect(() => {
    onScrollTop();
  }, [pathname]);

  const containerClassNames = clsx(styles.container, {
    [styles.containerBottom]: !!bottomType || orderStatusHasBottom,
    [styles.containerTop]: isStartedAnimation
  });

  return (
    <div className="page">
      <Top image={image} />
      <div className={containerClassNames}>
        <Header screen={screen} textParams={textParams} loading={isLoadingHeader} />
        <div className={styles.content}>
          {children}
          <SupportContacts />
        </div>
      </div>
      <Bottom type={bottomType} />
    </div>
  );
};

export default React.memo(MarketLayout);
