import React, {useEffect} from "react";
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import {useAppDispatch} from "store";
import {getProductInfo} from "store/market/thunk";
import {clearRequest} from "store/market/slice";
import {getBgImageStyles} from "pages/market/helpers";
import {getFormatRub} from "utils/currency";
import EmptyProduct from "./EmptyProduct";

import styles from "./styles.module.scss";

import {RootState} from "store";

const Product = () => {
  const {productId} = useParams();
  const {loadedProduct, product, productId: apiProductId} = useSelector((s: RootState) => s.market);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (productId && productId !== String(apiProductId)) {
      dispatch(getProductInfo(productId));
    }
    return () => {
      dispatch(clearRequest());
    };
  }, []);

  if (!loadedProduct) {return <EmptyProduct />;}
  if (!product) {return null;}

  return (
    <div className={styles.product}>
      <div className={styles.productImage} style={getBgImageStyles(product.image, true)}/>
      {product.description && <div className={styles.productDesc}>
        <div className={styles.productLabel}>Описание</div>
        <div className={styles.productDescText}>
          {product.description}
        </div>
      </div>}
      <div className={styles.productCost}>
        <div className={styles.productLabel}>Стоимость:</div>
        <div className={styles.productCostNumber}>{getFormatRub(product.price)}</div>
      </div>
    </div>
  );
};

export default Product;
