import React, {useCallback} from "react";
import {push} from "redux-first-history";
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import {useAppDispatch} from "store";
import CategoryItem from "./CategoryItem";

import styles from "./styles.module.scss";

import type {RootState} from "store";

const Sections: React.FC = () => {
  const {slug} = useParams();
  const dispatch = useAppDispatch();
  const sections = useSelector((state: RootState) => state.market.sections);

  const onClick = useCallback((categoryId: number) => {
    dispatch(push(`/${slug}/${categoryId}/`));
  }, []);

  return (
    <div className={styles.wrapper}>
      {sections?.map((section, i: number) => {
        if (!section?.categories?.length) {return null;}
        return (
          <div key={i} className={styles.category}>
            <div className={styles.categoryTitle}>{section.title}</div>
            <div className={styles.items}>
              {section?.categories.map((category, j: number) => (
                <CategoryItem key={`${i}-${j}`} {...category} onClick={onClick} />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Sections;
