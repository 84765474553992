import {yClientsApi} from "services";
import {YC_COMPANY} from "pages/service/consts";

import type {Appointment} from "pages/service/Model/Appointment";

export interface BookServiceData {
  phone: string;
  email: string;
  fullname: string;
  appointment: Appointment;
}

export async function getServices() {
  return yClientsApi.get(`company/${YC_COMPANY}/services`);
}

export async function getRecords(staff: number) {
  return yClientsApi.get(`book_staff_seances/${YC_COMPANY}/${staff}`);
}

export async function getRecordsByDate(staff: number, date: string, service: string | null) {
  return yClientsApi.get(`book_times/${YC_COMPANY}/${staff}/${date}`, {
    params: {
      service_ids: service
    }
  });
}

export async function bookRecord(data: BookServiceData) {
  return yClientsApi.post(`book_record/${YC_COMPANY}`, data);
}
