import {createSlice, current}   from "@reduxjs/toolkit";

import type {Slice, Reducer}    from "@reduxjs/toolkit";
import type {BaseState}         from "./Model";
import type {AnyAction}         from "redux";

export const baseState: BaseState = {
  errors: {},
  touched: {},
  submitting: false,
  canSubmit: true
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default function extend<T extends BaseState>(slice: Slice): Reducer<T, AnyAction> {
  const initialState = {...slice.getInitialState(), ...baseState};
  const wrappingSlice = createSlice({
    name: slice.name,
    initialState,
    reducers: {
      ...slice.reducer,
      ['HANDLE_CHANGE'](state, {payload}) {
        const {field, value} = payload;
        state[field] = value;
      },
      ['HANDLE_TOUCH'](state, {payload}) {
        const {field, value} = payload;
        state.touched[field] = value;
      },
      ['HANDLE_ERROR'](state, {payload}) {
        const {field, value} = payload;
        state.errors[field] = value;
      },
      ['SET_ERRORS'](state, {payload}) {
        state.errors = payload;
      },
      ['VALIDATE'](state, {payload}) {
        state.errors = {...current(state.errors), ...payload};
      },
      ['SET_STATE'](state, {payload}) {
        return {...current(state), ...payload};
      },
      ['RESET_STATE']() {
        return initialState;
      }
    }
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const wrappedReducer: Reducer<T, AnyAction> = (state: T, action: AnyAction) => slice.reducer(wrappingSlice.reducer(state, action), action);
  return wrappedReducer;
}
