import {api} from "services";

import type {PaymentInfo} from "pages/payment/Model/PaymentInfo";

export async function initPayment({vsid, firstName, email, phone, successUrl, failUrl}: {
  vsid?: number;
  firstName: string;
  email?: string;
  phone: string;
  successUrl: string,
  failUrl: string
}) {
  return api.post('valet/session/payment/init/', {
    vsid,
    first_name: firstName,
    email,
    phone,
    success_url: successUrl,
    fail_url: failUrl
  });
}

export async function checkStatus({orderId}: {
  orderId: string;
}) {
  return api.post<PaymentInfo>('billing/check-status', {
    human_order_id: orderId
  });
}
