import React, {useCallback} from "react";
import {useAppDispatch} from "store";
import {useSelector} from "react-redux";
import {startPayment} from "store/market/thunk";
import {PAYMENT_TYPES} from "pages/market/consts";

import styles from "./styles.module.scss";

import type {RootState} from "store";
import {clsx} from "clsx";

const ConfirmBottom: React.FC = () => {
  const model = useSelector((s: RootState) => s.market);
  const dispatch = useAppDispatch();

  const isHasOnlinePayment = model.cart?.paymentInfo?.paymentType?.slug === PAYMENT_TYPES.PREPAID;
  const isHasPaymentMethods = model.cart?.paymentInfo?.paymentMethods?.length;
  const submitting = model.submitting;

  const initPayment = useCallback(() => {
    if (submitting) {return;}
    dispatch(startPayment());
  }, [model, dispatch, submitting]);

  if (!isHasPaymentMethods) {
    return null;
  }

  return (
    <div className={clsx(styles.innerText, {[styles.disabled]: submitting})} onClick={initPayment}>
      {isHasOnlinePayment ? 'Перейти к оплате' : 'Оформить заказ'}
    </div>
  );
};

export default ConfirmBottom;
