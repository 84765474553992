import {useSelector} from "react-redux";

import type {RootState} from "store";

import styles from "./styles.module.scss";
import sharedStyles from "../../styles.module.scss";

const InfoRow = ({ label, value }: { label: string; value?: string | number }) => {
  if (!value) {return null;}
  return (
    <div className={styles.statusInfoStr}>
      <div className={styles.statusInfoLabel}>{label}:</div>
      <div className={styles.statusInfoValue}>{value}</div>
    </div>
  );
};

const StatusInfo = () => {
  const info = useSelector((s: RootState) => s.orderStatus.payment);
  if (!info) {return  null;}

  const {order, invoice, client} = info;
  const rows = [
    {label: "Заказ №", value: order?.orderId},
    {label: "Статус заказа", value: order?.status?.name},
    {label: "Статус оплаты", value: invoice?.status?.name},
    {label: "Метод оплаты", value: order?.paymentMethod?.name},
    {label: "Покупатель", value: client?.name},
    {label: "Телефон", value: client?.phone},
    {label: "Email", value: client?.email}
  ];

  return (
    <div className={sharedStyles.card}>
      <div className={sharedStyles.title}>Информация по заказу</div>
      {rows.map((row, index) => (
        <InfoRow key={index} label={row.label} value={row.value} />
      ))}
    </div>
  );
};

export default StatusInfo;
