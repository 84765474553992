import React from "react";
import type {HandleBlurAction, HandleChangeAction} from "./actions";
import type {BaseState} from "./Model";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface BaseActions<T extends BaseState = any> {
	handleChange?: HandleChangeAction<T>;
	handleBlur?: HandleBlurAction<T>;
}

interface FormContextProps {
	actions?: BaseActions;
	disabled?: boolean;
	prefix: string;
}

interface FormContextComponent extends FormContextProps {
	children: React.ReactElement | React.ReactElement[] | React.ReactNode;
}

export const Context = React.createContext<FormContextProps>({prefix: '', actions: {}});

export default function FormContextWrapper({children, prefix, actions, disabled}: FormContextComponent) {
	return (
		<Context.Provider value={{prefix, actions}}>
			<form>
				<fieldset disabled={disabled}>
					{children}
				</fieldset>
			</form>
		</Context.Provider>
	);
}
