import React                from "react";
import {useSelector}        from "react-redux";
import CartItem             from "./CartItem";
import CartItemShort        from "./CartItemShort";
import groupBy              from "utils/groupBy";
import declOfNum            from "utils/declOfNum";
import {getFormatRub}       from "utils/currency";

import {clsx}               from "clsx";
import styles               from "./styles.module.scss";

import type {RootState}     from "store";
import type {CartItemPrepared} from "pages/market/Model/Cart";

function prepareItems(data: Record<string, CartItemPrepared[]>) {
  return Object.values(data).map((group) => {
    const category = group[0]?.product?.categories?.[0];
    const items = group.sort((a, b) => a.product.id - b.product.id);
    return { title: category?.title, id: category?.id, items };
  });
}

function calculateSums(data: Record<string, CartItemPrepared[]>) {
  return Object.entries(data).map(([name, items]) => {
    const total = items.reduce(
      (acc, item) => {
        acc.amount += item.quantity;
        acc.value += item.product.price * item.quantity;
        return acc;
      },
      {amount: 0, value: 0}
    );
    return {...total, name, order: name === "goods" ? 1 : 2};
  });
}

function getSumLabel(name: string, amount: number): string {
  const labels = {
    services: ["услуга", "услуги", "услуг"],
    goods: ["товар", "товара", "товаров"]
  };
  return `${amount} ${declOfNum(amount, labels[name] || ["", "", ""])}`;
}

const CartProductList: React.FC<{isPayment?: boolean, children?: React.ReactElement | React.ReactElement[]}> = ({isPayment, children}) => {
  const model = useSelector((state: RootState) => state.market);
  const {cart, loadedCart} = model;
  const isLoading = !loadedCart;

  if (!cart) {return null;}

  const groupedItems = groupBy(cart?.products, ({product}) => product?.categories?.[0]?.id);
  const preparedItems = prepareItems(groupedItems);

  const sums = calculateSums(groupBy(cart?.products, ({ product }) => product?.productType));
  const sumContent = (
    <div className={clsx(styles.amountCard, {[styles.amountCardLoading]: isLoading})}>
      {sums
        .sort((a, b) => a.order - b.order)
        .map(({ name, value, amount }, idx) => (
          <div key={idx} className={styles.amountItem}>
            <span>{getSumLabel(name, amount)}</span>
            <span>{getFormatRub(value)}</span>
          </div>
        ))}
      {sums?.length ? <div className={styles.amountSum}>
        <span>Итого:</span>
        <span>{getFormatRub(cart.totalCost)}</span>
      </div> : <div className={styles.cartEmpty}>Корзина пустая</div>}
    </div>
  );

  const content = preparedItems.map(({title, items}, idx) => (
    <div className={styles.items} key={idx}>
      <div className={styles.itemsCategory}>
        <div className={styles.itemsCategoryTitle}>{title}</div>
      </div>
      {items.map((item, index) => {
        if (isPayment) {
          return <CartItemShort
            key={`${idx}-${index}`}
            title={item.product.title}
            image={item.product?.image}
            amount={item.quantity}
            price={item.product.price}
          />;
        }

        return (
          <CartItem
            key={`${idx}-${index}`}
            productId={item.product.id}
            categoryId={item.product.categories[0].id}
            title={item.product.title}
            image={item.product?.image}
            amount={item.quantity}
            price={item.product.price}
            productType={item.product.productType}
          />
        );
      })}
    </div>
  ));

  return (
    <div className={styles.wrapper}>
      {content}
      {children}
      {sumContent}
    </div>
  );
};

export default CartProductList;
