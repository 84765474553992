import type {Keys} from "utils/local-storage/consts";

export function getItem(key: valueof<Keys>): string | null {
  try{
    if(typeof window === "undefined"){
      return null;
    }

    return localStorage.getItem(key);
  }catch(ex){
    console.error(ex);
  }

  return null;
}

export function setItem(key: valueof<Keys>, obj: string): void {
  try{
    if(typeof window === "undefined"){
      return;
    }
    localStorage.setItem(key, obj);
  }catch(ex){
    console.error(ex);
  }
}

export function removeItem(key: valueof<Keys>): void {
  try{
    if(typeof window === "undefined"){
      return;
    }

    localStorage.removeItem(key);
  }catch(ex){
    console.error(ex);
  }
}
