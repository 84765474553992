import React, {useCallback} from "react";
import {useSelector} from "react-redux";
import {useAppDispatch} from "store";
import {PAYMENT_TYPES} from "../../../consts";
import {setPaymentMethod} from "store/market/slice";
import {PaymentMethod} from "./PaymentMethod";

import styles from "./styles.module.scss";

import type {RootState} from "store";

const PaymentMethods: React.FC = () => {
  const paymentInfo = useSelector((s: RootState) => s.market.cart?.paymentInfo);
  const loadedCartPayment = useSelector((s: RootState) => s.market.loadedCartPayment);
  const active = useSelector((s: RootState) => s.market.paymentMethod);
  const paymentTypeSlug = paymentInfo?.paymentType?.slug;
  const isShowAdditionalInfo = paymentTypeSlug === PAYMENT_TYPES.AFTER_CONFIRM || paymentTypeSlug === PAYMENT_TYPES.POST_PAID;

  const dispatch = useAppDispatch();

  const handleChange = useCallback((method: string) => {
    dispatch(setPaymentMethod(method));
  }, []);

  if (!loadedCartPayment || !paymentInfo?.paymentMethods || !paymentInfo?.paymentType) {return null;}

  return (
    <>
      {isShowAdditionalInfo && (
        <div className={styles.card}>
          {paymentTypeSlug === PAYMENT_TYPES.AFTER_CONFIRM && <div className={styles.paymentInfoDescTitle}>Оплата после подтверждения</div>}
          {paymentTypeSlug === PAYMENT_TYPES.POST_PAID && <div className={styles.paymentInfoDescTitle}>Оплата после оказания</div>}
          <div className={styles.paymentInfoDescText}>
            В вашей корзине есть товары или услуги, которые требуют подтверждения. После оформления заказа, с вами свяжется менеджер, чтобы согласовать детали.
          </div>
        </div>
      )}
      <div className={styles.card}>
        <div className={styles.paymentLabel}>Доступные типы оплаты</div>
        <div className={styles.paymentMethodsList}>
        {paymentInfo?.paymentMethods?.map(method => (
          <PaymentMethod key={method.slug} method={method} onChange={handleChange} active={active} />
        ))}
        </div>
      </div>
    </>
  );
};

export default PaymentMethods;
