import React from "react";

import styles from "./styles.module.scss";

const EmptyProducts = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.items}>
        <div className={styles.emptyItem} />
        <div className={styles.emptyItem} />
        <div className={styles.emptyItem} />
        <div className={styles.emptyItem} />
      </div>
    </div>
  );
};

export default React.memo(EmptyProducts);
