import debounce               from "lodash.debounce";
import {useParams}            from "react-router";
import {useEffect}            from "react";
import {useAppDispatch}       from "store";
import {set}                  from "utils/redux/actions";
import {ACTION_TYPE, PREFIX}  from "store/market/consts";
import {addProduct, deleteProduct, initMarket} from "store/market/thunk";
import {getItem, setItem}     from "utils/local-storage";
import LS_KEY                 from "utils/local-storage/consts";
import {BASE_URL}             from "services";
import {addPendingRequest, clearPendingRequest} from "store/market/slice";
import {MARKET_SCREEN} from "pages/market/consts";

import type {Dispatch}        from "utils/redux/Model";
import type {AppDispatch, RootState} from "store";
import type {MarketState}   from "store/market/slice";
import type {Params} from "react-router";

export interface HeaderTextParams {
  fullAddress: string;
  objectName: string;
  categoryTitle?: string;
  productTitle?: string;
  sectionTitle?: string;
  paymentSucceeded: boolean;
  paymentFailed: boolean;
}

interface HeaderTextMap {
  title: string | null;
  description: string | null
}

export const getHeaderText = (screen: string, params: HeaderTextParams): HeaderTextMap => {
  const textMap: HeaderTextMap = {title: null, description: null};
  if (screen === MARKET_SCREEN.CART) {
    textMap.title = 'Корзина';
  } else if (screen === MARKET_SCREEN.CONFIRMATION) {
    textMap.title = 'Оформление';
  } else if (screen === MARKET_SCREEN.PRODUCTS) {
    textMap.title = params.categoryTitle || null;
    textMap.description = params.sectionTitle || null;
  } else if (screen === MARKET_SCREEN.PRODUCT) {
    textMap.title = params.productTitle || null;
    textMap.description = params.categoryTitle || null;
  } else if (screen === MARKET_SCREEN.CATEGORIES) {
    textMap.title = params.objectName || null;
    textMap.description = params.fullAddress || null;
  } else if (screen === MARKET_SCREEN.STATUS) {
    if (params?.paymentFailed) {
      textMap.title = 'Ошибка при оплате';
    } else {
      const suffix = params?.paymentSucceeded ? 'оплачен' : 'оформлен';
      textMap.title = 'Заказ ' + suffix;
    }
  }
  return textMap;
};

export const getImage = (screen: string, mainImage?: string, categoryImage?: string): string => {
  const defaultImage = "/bg.jpg";
  if (screen === MARKET_SCREEN.PRODUCT || screen === MARKET_SCREEN.PRODUCTS) {
    return categoryImage || mainImage || defaultImage;
  }
  return mainImage || defaultImage;
};

export const getMarketScreen = (url?: string, params?: Readonly<Params<string>>): string => {
  const isHasSlug = !!params?.slug;
  const isHasProductId = !!params?.productId;
  const isHasCategoryId = !!params?.categoryId;
  const isCart = url?.includes("/cart");
  const isConfirmation = url?.includes("/confirmation");
  const isOrderStatus = url?.includes("/status");
  const isMarketPage = !isCart && !isConfirmation && !isOrderStatus;

  if (isHasSlug && !isHasProductId && !isHasCategoryId && isMarketPage) {
    return MARKET_SCREEN.CATEGORIES;
  } else if (isHasSlug && isHasCategoryId && !isHasProductId) {
    return MARKET_SCREEN.PRODUCTS;
  } else if (isHasSlug && isHasCategoryId && isHasProductId) {
    return MARKET_SCREEN.PRODUCT;
  } else if (isHasSlug && isCart) {
    return MARKET_SCREEN.CART;
  } else if (isHasSlug && isConfirmation) {
    return MARKET_SCREEN.CONFIRMATION;
  } else if (isHasSlug && isOrderStatus) {
    return MARKET_SCREEN.STATUS;
  }
  return '';
};


export const getSidFromLs = (marketId: string | number): string | null => {
  if (!marketId) {return null;}
  return getItem(`${LS_KEY.MARKET}${marketId}`);
};

export const compareAndUpdateSid = (id: string | number, sid: string , dispatch: Dispatch): void => {
  if (!id || !sid) {return;}
  const prevSid = getItem(`${LS_KEY.MARKET}${id}`);
  if (prevSid !== sid) {
    setItem(`${LS_KEY.MARKET}${id}`, sid);
    dispatch(set(PREFIX, {sid}));
  }
};

export const useMarketInitialization = (hasCart = true) => {
  const {slug} = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!slug) {return;}
    dispatch(initMarket({slug, hasCart}));
  }, [slug]);
};

export const getBgImageStyles = (image?: string | null, useBaseUrl?: boolean) => {
  if (image && useBaseUrl) {return {backgroundImage: `url(${BASE_URL}${image})`};}
  if (image) {return {backgroundImage: `url(${image})`};}
  return {};
};

export const getCategories = (sections) => {
  return sections?.reduce((acc: {id: number; section: number; title: string; description: string; thumbImage?: string;}[], el) => {
    if (el?.categories?.length) {
      acc = [...acc, ...el.categories];
    }
    return acc;
  }, []);
};

export const getRequestKey = (categoryId: string | number, productId: string | number) => {
  return `${categoryId}_${productId}`;
};

export const debouncedAddProduct = (productId: string | number, categoryId: string | number, quantity = 1) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(set(PREFIX, {loadedCart: false}));
    dispatch(addPendingRequest({categoryId, productId, quantity, action: ACTION_TYPE.ADD}));
    executeDebouncedAddProduct(productId, categoryId, dispatch, getState);
  };

export const debouncedDeleteProduct = (productId: string | number, categoryId: string | number, quantity = 1) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(set(PREFIX, {loadedCart: false}));
    dispatch(addPendingRequest({categoryId, productId, quantity, action: ACTION_TYPE.DELETE}));
    executeDebouncedDeleteProduct(productId, categoryId, dispatch, getState);
  };


const executeDebouncedAddProduct = debounce(
  (productId: string | number, categoryId: string | number, dispatch: AppDispatch, getState: () => RootState) => {
    const pendingRequests = getState().market.pendingRequests.add;
    const key = getRequestKey(categoryId, productId);
    const item = pendingRequests.find(it => it.key === key);
    if (item?.quantity === 0) {return;}

    if (item) {
      dispatch(addProduct({productId: productId, categoryId: categoryId, quantity: item.quantity})).then(() => {
        dispatch(clearPendingRequest({categoryId, productId, action: ACTION_TYPE.ADD}));
      });
    }
  }, 500, {leading: false, trailing: true}
);

const executeDebouncedDeleteProduct = debounce(
  (productId: string | number, categoryId: string | number, dispatch: AppDispatch, getState: () => RootState) => {
    const pendingRequests = getState().market.pendingRequests.delete;
    const key = getRequestKey(categoryId, productId);
    const item = pendingRequests.find(it => it.key === key);
    if (item?.quantity === 0) {return;}

    if (item) {
      dispatch(deleteProduct({productId: productId, categoryId: categoryId, quantity: item.quantity})).then(() =>{
        dispatch(clearPendingRequest({categoryId, productId, action: ACTION_TYPE.DELETE}));
      });
    }
  }, 500, {leading: false, trailing: true}
);

export const getAmountProduct = (categoryId: string | number, productId: string | number, model: MarketState): number | undefined => {
  const product = model.cart?.products.find(it => it.product.id === productId && it.product.categories?.[0].id === categoryId);
  const key = getRequestKey(categoryId, productId);
  const pendingAdd = model.pendingRequests.add.find(it => it.key === key)?.quantity;
  const pendingDelete = model.pendingRequests.delete.find(it => it.key === key)?.quantity;

  if (product && pendingDelete && pendingAdd) {
    return product.quantity + pendingAdd - pendingDelete;
  } else if (product && pendingDelete) {
    return product.quantity - pendingDelete;
  } else if (product && pendingAdd) {
    return product.quantity + pendingAdd;
  } else if (!product && pendingDelete) {
    return pendingDelete;
  } else if (!product && pendingAdd) {
    return pendingAdd;
  }
  return product?.quantity;
};

export const addItem = (key: string, items: {key: string; quantity: number;}[]) => {
  const item = items.find(it => it.key === key);
  const filteredItems = items.filter(it => it.key !== key);

  if (item) {
    return [...filteredItems, {key, quantity: item.quantity + 1}];
  }

  return [...items, {key, quantity: 1}];
};

interface OrderStatusParams {
  paymentSucceeded: boolean;
  paymentFailed: boolean;
}

export function getOrderStatusParams(params: URLSearchParams): OrderStatusParams {
  const paymentSucceeded = params.get("paymentStatus") === "true";
  const paymentFailed = params.get("paymentStatus") === "false";

  return {paymentSucceeded, paymentFailed};
}
