import {createSlice}    from '@reduxjs/toolkit';
import {PREFIX}         from "./consts";
import {getPaymentInfo, openPayment} from "./thunk";
import {
  GV_BILLING, INVOICE_STATUS,
  ORDER_STATUS, PAYMENT_TYPES
} from "pages/market/consts";

import type {FormattedPaymentInfo} from "pages/order-status/Model/PaymentInfo";

export interface OrderStatusState {
  loaded: boolean;
  payment: FormattedPaymentInfo | null;
  hasBottomButton: boolean;
  submitting: boolean;
}

const initialState: OrderStatusState = {
  loaded: true,
  submitting: false,
  payment: null,
  hasBottomButton: false
};

const slice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPaymentInfo.fulfilled, (state: OrderStatusState, {payload}) => {
      state.payment = payload;
      state.hasBottomButton = payload.order?.status?.systemName === ORDER_STATUS.NEW &&
        payload.order?.paymentMethod?.slug === GV_BILLING &&
        payload.order?.paymentType?.slug === PAYMENT_TYPES.PREPAID &&
        payload.order?.status?.systemName === INVOICE_STATUS.NOT_PAID;
      state.loaded = true;
    });
    builder.addCase(getPaymentInfo.pending, (state: OrderStatusState) => {
      state.loaded = false;
    });
    builder.addCase(getPaymentInfo.rejected, (state: OrderStatusState) => {
      state.loaded = true;
    });
    builder.addCase(openPayment.fulfilled, (state: OrderStatusState) => {
      state.submitting = false;
    });
    builder.addCase(openPayment.pending, (state: OrderStatusState) => {
      state.submitting = true;
    });
    builder.addCase(openPayment.rejected, (state: OrderStatusState) => {
      state.submitting = false;
    });
  }
});

export default slice.reducer;
