import React from "react";
import {Route, Routes} from "react-router-dom";
import ErrorBoundary from 'utils/providers/ErrorBoundary';
import URLS from "./urls";
import App from "./index";
import Market from "./market";
import OrderStatus from "./order-status";
import Category from "./market/views/components/Category";
import Product from "./market/views/components/Product";
import Cart from "./market/views/components/Cart";
import Payment from "./market/views/components/Payment";
import Sections from "./market/views/components/Sections";

export const Router = React.memo(() => {
  return (
    <ErrorBoundary>
      <Routes>
        <Route path={URLS.ROOT} element={<App />} />
        <Route path={URLS.MARKET} element={<Market />}>
          <Route path={URLS.MARKET} element={<Sections />} />
          <Route path={URLS.MARKET_ORDER_STATUS} element={<OrderStatus />} />
          <Route path={URLS.MARKET_PRODUCT} element={<Product />} />
          <Route path={URLS.MARKET_CATEGORY} element={<Category />} />
          <Route path={URLS.MARKET_CART} element={<Cart />} />
          <Route path={URLS.MARKET_CONFIRMATION} element={<Payment />} />
        </Route>
      </Routes>
    </ErrorBoundary>
  );
});
