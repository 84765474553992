import React from "react";
import {getFormatRub} from "utils/currency";
import {getBgImageStyles} from "../../../helpers";

import styles from "./styles.module.scss";

interface CartItemShortProps {
  title: string;
  price: number;
  amount: number;
  image: string | null;
}

const CartItemShort: React.FC<CartItemShortProps> = ({
  image,
  title,
  price,
  amount
}) => {
  return (
    <div className={styles.item}>
      <div className={styles.itemShortImage} style={getBgImageStyles(image, true)} />
      <div className={styles.itemShortInfo}>
        <div className={styles.itemLabel}><span>{title}</span></div>
        <div className={styles.itemShortBlock}>
          <div className={styles.itemPrice}>{getFormatRub(price * amount)}</div>
          <div className={styles.itemAmount}>{amount} шт.</div>
        </div>
      </div>
    </div>
  );
};

export default CartItemShort;
