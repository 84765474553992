export function formatPhone(phone?: string | null): string {
  if (!phone) {
    return '';
  }

  const cleanedPhone = phone.replace(/\D/g, '');
  let normalizedPhone = cleanedPhone;

  if (cleanedPhone.startsWith('8') && cleanedPhone.length === 11) {
    normalizedPhone = '7' + cleanedPhone.substring(1);
  }

  if (normalizedPhone.length !== 11 || !normalizedPhone.startsWith('7')) {
    return phone;
  }

  const phoneParsed = /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/.exec(normalizedPhone);
  if (!phoneParsed) {
    return phone;
  }

  return `+${phoneParsed[1]} (${phoneParsed[2]}) ${phoneParsed[3]}-${phoneParsed[4]}-${phoneParsed[5]}`;
}
