export const IconArrow = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="arrow_r">
        <path id="Oval"
              d="M0.5 6C0.5 2.96243 2.96243 0.5 6 0.5C9.03757 0.5 11.5 2.96243 11.5 6C11.5 9.03757 9.03757 11.5 6 11.5C2.96243 11.5 0.5 9.03757 0.5 6Z"
              stroke="#C3C7DB"/>
        <path id="Path 3" d="M5 3L8 6L5 9" stroke="#C3C7DB" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
    </svg>
  );
};
