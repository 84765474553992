import React, {useCallback, useContext} from "react";
import {useSelector} from "react-redux";
import {useAppDispatch} from "store";
import {Context} from "utils/redux/context";

import {clsx} from "clsx";
import styles from "./styles.module.scss";

interface CheckboxProps {
  name: string;
  prefix?: string;
  label: string | React.ReactElement | React.ReactElement[];
  value?: boolean;
  onChange?: (name: string, val: boolean) => void;
  error?: string | null;
}

const Checkbox: React.FC<CheckboxProps> = ({
  name,
  prefix,
  label,
  onChange,
  value = false,
  error
}) => {
  const dispatch = useAppDispatch();
  const context = useContext(Context);
  const model = prefix as string || context?.prefix;
  const actions = context?.actions;

  const fieldValue = useSelector(s => s?.[model]?.[name]) || value;
  const fieldError = useSelector(s => s?.[model]?.['errors']?.[name]) || error || null;

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(name, e.target.checked);
    actions?.handleChange && dispatch(actions.handleChange(name, e.target.checked));
  }, [onChange, name, actions]);

  return (
    <div>
      <div className={styles.checkbox}>
          <div className={styles.checkboxWrapper}>
            <input
              className={styles.checkboxInput}
              id={name}
              name={name}
              type="checkbox"
              checked={fieldValue}
              onChange={handleChange}
            />
            <label className={clsx(styles.checkboxLabel, {[styles.checkboxChecked]: fieldValue})} htmlFor={name}  />
          </div>
          <div className={styles.checkboxText}>{label}</div>
      </div>
      {!!fieldError?.length && <div className={styles.error}>{fieldError}</div>}
    </div>
  );
};

export default React.memo(Checkbox);
