import React, {useCallback} from "react";
import {Icon} from "components/Icon";
import {useAppDispatch} from "store";
import {debouncedAddProduct, debouncedDeleteProduct} from "pages/market/helpers";
import {COUNTER_TYPE} from "./const";
import {PRODUCT_TYPE} from "pages/market/consts";

import {clsx} from "clsx";
import styles from "./styles.module.scss";

interface CounterProps {
  type: string;
  productType?: string;
  productId: number;
  categoryId: number;
  amount?: number;
  disabledAdd?: boolean;
  disabledRemove?: boolean;
}

export const Counter: React.FC<CounterProps> = ({
  type,
  productType,
  productId,
  categoryId,
  amount,
  disabledAdd,
  disabledRemove
}) => {
  const dispatch = useAppDispatch();
  const removeDisabled = disabledRemove || amount === 0;
  const addDisabled = disabledAdd || (amount === 1 && productType === PRODUCT_TYPE.SERVICES);

  const remove = useCallback(() => {
    if (removeDisabled) {return;}
    dispatch(debouncedDeleteProduct(productId, categoryId));
  }, [productId, categoryId, removeDisabled]);

  const add = useCallback(() => {
    if (addDisabled) {return;}
    dispatch(debouncedAddProduct(productId, categoryId));
  }, [productId, categoryId, addDisabled]);

  const counterClassNames = clsx(styles.counter, {
      [styles.counterProductCard]: type === COUNTER_TYPE.PRODUCT_CARD,
      [styles.counterCart]: type === COUNTER_TYPE.CART
    });

  return (
    <div className={counterClassNames}>
      <div className={styles.counterInner}>
        <div className={clsx(styles.counterButton, {[styles.counterButtonDisabled]: removeDisabled})} onClick={remove}>
          <Icon.Minus/>
        </div>
        <div className={styles.counterAmount}>{amount || 0}</div>
        <div className={clsx(styles.counterButton, {[styles.counterButtonDisabled]: addDisabled})} onClick={add}>
          <Icon.Plus/>
        </div>
      </div>
    </div>
  );
};
