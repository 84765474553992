import axios from 'axios';

export const API_URL = process.env.REACT_APP_BASE_URL + '/api/v1' || 'https://dev.getvalet.ru/api/v1';
export const MEDIA_URL = process.env.REACT_APP_BASE_URL + '/api/media' || 'https://dev.getvalet.ru/api/media';
export const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://dev.getvalet.ru';
const PROXY_API_URL = '/vlt';
const PROXY_YC_URL = '/yc';

const isPublic =
  process.env.NODE_ENV === 'production' ||
  process.env.REACT_APP_MODE === 'staging' ||
  process.env.REACT_APP_MODE === 'staging-dev2' ||
  process.env.REACT_APP_MODE === 'staging-dev3';
const baseUrl = !isPublic ? PROXY_API_URL : API_URL;

export const api = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: '*/*',
    'content-type': 'application/json'
  }
});

export const yClientsApi = axios.create({
  withCredentials: true,
  baseURL: PROXY_YC_URL,
  headers: {
    Accept: 'application/vnd.yclients.v2+json',
    'Content-Type': 'application/json',
    Pragma: 'no-cache',
    Expires: '0',
    Authorization: `Bearer ${process.env.REACT_APP_YC_TOKEN}, User ${process.env.REACT_APP_YC_USER}`
  }
});
