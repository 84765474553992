interface Urls {
  ROOT: string;
  OTHERS: string;
  ANY: string;
  MARKET: string;
  MARKET_CATEGORY: string;
  MARKET_PRODUCT: string;
  MARKET_CART: string;
  MARKET_CONFIRMATION: string;
  MARKET_ORDER_STATUS: string;
}

export const URLS = {} as Urls;

export default URLS;

URLS.ROOT = '/';
URLS.OTHERS = '*';

URLS.MARKET = URLS.ROOT + ':slug';
URLS.MARKET_ORDER_STATUS = URLS.MARKET + '/status';
URLS.MARKET_CATEGORY = URLS.MARKET + '/:categoryId';
URLS.MARKET_PRODUCT = URLS.MARKET_CATEGORY + '/:productId';
URLS.MARKET_CART = URLS.MARKET + '/cart';
URLS.MARKET_CONFIRMATION = URLS.MARKET + '/confirmation';

URLS.ANY = '/*';
