import {createSlice}            from '@reduxjs/toolkit';
import {STATUS}                 from "store/consts";
import {PAYMENT_PAGE}           from "pages/payment/consts";
import {
  startPaymentProcess,
  checkPaymentStatus
}                               from "./thunk";
import extend, {baseState}      from "utils/redux/extend";
import {PREFIX}                 from "./consts";

import type {PaymentInfo}       from "pages/payment/Model/PaymentInfo";
import type {BaseState}         from "utils/redux/Model";

interface PaymentState extends BaseState {
  loading: valueof<typeof STATUS>;
  submitting: boolean;
  paymentPage: valueof<typeof PAYMENT_PAGE> | null;
  email: string;
  paymentInfo: PaymentInfo | null;
}

const initialState: PaymentState = {
  loading: STATUS.FULFILLED,
  paymentPage: null,
  email: '',
  paymentInfo: null,
  ...baseState
};

const slice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {
    setPaymentPage(state, {payload}) {
      state.paymentPage = payload;
    },
    closePayment(state) {
      state.paymentPage = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(startPaymentProcess.fulfilled, (state) => {
      state.submitting = false;
    });
    builder.addCase(startPaymentProcess.pending, (state) => {
      state.submitting = true;
    });
    builder.addCase(startPaymentProcess.rejected, (state) => {
      state.submitting = false;
    });
    builder.addCase(checkPaymentStatus.fulfilled, (state, {payload}) => {
      state.paymentInfo = payload;
      state.loading = STATUS.FULFILLED;
    });
    builder.addCase(checkPaymentStatus.pending, (state) => {
      state.loading = STATUS.LOADING;
    });
    builder.addCase(checkPaymentStatus.rejected, (state) => {
      state.loading = STATUS.REJECTED;
    });
  }
});

export const {
  setPaymentPage,
  closePayment
} = slice.actions;

export default extend<PaymentState>(slice);
