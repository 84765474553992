import {createSlice}            from '@reduxjs/toolkit';
import {STATUS}                 from "store/consts";
import {SERVICE, SERVICE_PAGE, STEPS} from "pages/service/consts";
import {bookServiceRecord, getServiceList, getStaffRecords, getStaffRecordsByDate} from "store/service/thunk";
import {formatPromiseItems}     from "./helpers";

import type {ServiceItem}       from "pages/service/Model/ServiceItem";
import type {ServiceInfo}       from "pages/service/Model/ServiceInfo";
import type {StaffItem, PromiseItem} from "./helpers";

interface ServiceState {
  loading: valueof<typeof STATUS>;
  category: valueof<typeof SERVICE> | null;
  servicePage: valueof<typeof SERVICE_PAGE> | null;
  serviceInfo?: ServiceInfo | null | any;
  productList: ServiceItem[];
  field: Dictionary;
  error: Dictionary;
  touched: Dictionary;
  step: valueof<typeof STEPS>;
  seances: StaffItem[];
  isAskedNextDay: boolean;
  submitting: boolean;
}

const initialState: ServiceState = {
  loading: STATUS.FULFILLED,
  category: null,
  servicePage: null,
  serviceInfo: null,
  productList: [],
  field: {
    ['phone']: '+7'
  },
  error: {},
  touched: {},
  step: STEPS.INITIAL,
  seances: [],
  isAskedNextDay: false,
  submitting: false
};

const counterSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setServicePage(state, {payload}) {
      state.servicePage = payload;
    },
    setServiceCategory(state, {payload}) {
      state.category = payload;
    },
    closeService(state) {
      state.servicePage = null;
      state.category = null;
      state.step = STEPS.INITIAL;
      state.serviceInfo = null;
      state.field["name"] = '';
      state.field["phone"] = '';
      state.error = {};
      state.touched = {};
    },
    setService(state, {payload}) {
      state.serviceInfo = state?.productList?.find(el => el.id === payload);
    },
    setError(state, {payload}) {
      const {field, value} = payload;
      state.error[field] = value;
    },
    setTouched(state, {payload}) {
      const {field, value} = payload;
      state.touched[field] = value;
    },
    setField(state, {payload}) {
      const {field, value} = payload;
      state.field[field] = value;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getServiceList.fulfilled, (state, {payload}) => {
      state.loading = STATUS.FULFILLED;
      state.productList = payload?.filter((el: ServiceItem) => el.category_id === state.category) || [];
    });
    builder.addCase(getServiceList.pending, (state) => {
      state.loading = STATUS.LOADING;
    });
    builder.addCase(getServiceList.rejected, (state, {payload}) => {
      state.loading = STATUS.REJECTED;
      console.error(payload, 'ERROR_CODE');
    });
    builder.addCase(getStaffRecords.fulfilled, (state, {payload}) => {
      state.loading = STATUS.FULFILLED;
      const seances = formatPromiseItems(payload as unknown as PromiseItem[]);
      if (seances?.length) {
        state.seances = seances;
      } else {
        state.isAskedNextDay = true;
      }
    });
    builder.addCase(getStaffRecords.pending, (state) => {
      state.loading = STATUS.LOADING;
    });
    builder.addCase(getStaffRecords.rejected, (state, {payload}) => {
      state.loading = STATUS.REJECTED;
      console.error(payload, 'ERROR_CODE');
    });
    builder.addCase(bookServiceRecord.fulfilled, (state) => {
      state.loading = STATUS.FULFILLED;
      state.step = STEPS.LAST;
      state.submitting = false;
    });
    builder.addCase(bookServiceRecord.pending, (state) => {
      state.loading = STATUS.LOADING;
      state.submitting = true;
    });
    builder.addCase(bookServiceRecord.rejected, (state, {payload}) => {
      state.loading = STATUS.REJECTED;
      state.submitting = false;
      console.error(payload, 'ERROR_CODE');
    });
    builder.addCase(getStaffRecordsByDate.fulfilled, (state, {payload}) => {
      state.loading = STATUS.FULFILLED;
      state.seances = formatPromiseItems(payload as unknown as PromiseItem[]);
    });
    builder.addCase(getStaffRecordsByDate.pending, (state) => {
      state.loading = STATUS.LOADING;
    });
    builder.addCase(getStaffRecordsByDate.rejected, (state, {payload}) => {
      state.loading = STATUS.REJECTED;
      console.error(payload, 'ERROR_CODE');
    });
  }
});

export const {
  setServiceCategory,
  setServicePage,
  closeService,
  setService,
  setError,
  setField,
  setTouched
} = counterSlice.actions;
export default counterSlice.reducer;
