import React, {useCallback} from "react";
import {useAppDispatch} from "store";
import {Icon} from "components/Icon";
import {Counter} from "../Counter";
import {COUNTER_TYPE} from "../Counter/const";
import {getFormatRub} from "utils/currency";
import {getBgImageStyles} from "pages/market/helpers";
import {debouncedAddProduct} from "pages/market/helpers";

import styles from "./styles.module.scss";

interface ProductItemProps {
  openProductItem: (productId: number) => void;
  categoryId: number;
  productId: number;
  id: number;
  title: string;
  image?: string;
  disabledAdd?: boolean;
  amount: number;
  price?: number;
  productType: string;
}

const ProductItem: React.FC<ProductItemProps> = ({
  openProductItem,
  productId,
  categoryId,
  title,
  image,
  amount,
  price,
  disabledAdd,
  productType
}) => {
  const dispatch = useAppDispatch();
  const isShowCounter = !!amount && amount > 0;

  const add = useCallback(() => {
    if (disabledAdd) {return;}
    dispatch(debouncedAddProduct(productId, categoryId));
  }, [productId, categoryId, disabledAdd]);

  const open = useCallback(() => {
    openProductItem(productId);
  }, [productId]);

  return (
    <div className={styles.item}>
      <div className={styles.itemImage} style={getBgImageStyles(image, true)} onClick={open}/>
      <div className={styles.itemInner}>
        <div className={styles.itemLabel} onClick={open}>{title}</div>
        {!isShowCounter && (
          <div className={styles.itemButton} onClick={add}>
            <span className={styles.itemButtonIconWrapper}><Icon.Shop/></span>
            <div className={styles.itemButtonPrice}>{getFormatRub(price)}</div>
          </div>
        )}
        {isShowCounter && <Counter type={COUNTER_TYPE.PRODUCT_CARD} productId={productId} categoryId={categoryId} amount={amount} productType={productType} />}
      </div>
    </div>
  );
};

export default React.memo(ProductItem);
