import React, {useCallback} from "react";
import {useAppDispatch} from "store";
import {deleteItemProduct} from "store/market/thunk";
import {Icon} from "components/Icon";
import {Counter} from "../Counter";
import {COUNTER_TYPE} from "../Counter/const";
import {getFormatRub} from "utils/currency";
import {getBgImageStyles} from "../../../helpers";

import styles from "./styles.module.scss";

interface CartItemProps {
  title: string;
  image: string | null;
  price: number;
  amount: number;
  productId: number;
  categoryId: number;
  productType: string;
}

const CartItem: React.FC<CartItemProps> = ({
  title,
  image,
  price,
  amount,
  productId,
  categoryId,
  productType
}) => {
  const dispatch = useAppDispatch();

  const handleDelete = useCallback(() => {
    dispatch(deleteItemProduct({productId, categoryId}));
  }, [dispatch, productId, categoryId]);

  return (
    <div className={styles.item}>
      <div className={styles.itemImage} style={getBgImageStyles(image, true)} />
      <div className={styles.itemInfo}>
        <div className={styles.itemLabel}>
          <span>{title}</span>
          <div className={styles.itemDelete} onClick={handleDelete}><Icon.Delete/></div>
        </div>
          <div className={styles.itemBottom}>
            <Counter type={COUNTER_TYPE.CART} productId={productId} categoryId={categoryId} amount={amount} productType={productType} />
            <span className={styles.itemPrice}>{getFormatRub(price * amount)}</span>
          </div>
      </div>
    </div>
  );
};

export default CartItem;
