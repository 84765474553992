import {getFullAddress} from "utils/address";
import type {Marketplace, MarketplaceCategory, MarketplaceResponse, MarketplaceSection} from "./Model/Marketplace";
import type {Category, CategoryResponse} from "./Model/Category";
import type {Product, ProductResponse} from "./Model/Product";
import type {Cart, CartPaymentInfo, CartResponse} from "./Model/Cart";
import type {ObjectData, ObjectResponse} from "./Model/Object";

export const getFormattedCart = (data: CartResponse): Cart => {
  const products = data.result.cart_product_items?.map(it => {
    return {
      id: it.id,
      quantity: it.quantity,
      product: {
        id: it.product.id,
        title: it.product.title,
        categories: it.product.categories,
        productType: it.product.product_type,
        price: it.product.price_for_client,
        discountedPrice: it.product.discounted_price,
        image: it.product.thumbnail_image
      }
    };
  });

  let paymentInfo: CartPaymentInfo | null = null;
  if (data?.result?.payment_info) {
    paymentInfo = {
      paymentType: data?.result?.payment_info.payment_type,
      paymentMethods: data?.result?.payment_info?.payment_methods
    };
  }

  return {
    id: data.result.id,
    sid: data.result.sid,
    marketId: data.result.marketplace,
    products,
    itemsCount: data.result.products_items_count,
    totalCost: data.result.cart_total_cost,
    paymentInfo
  };
};

export const getFormattedObject = (data: ObjectResponse): ObjectData => {
  return {
    name: data.name,
    image: data?.main_cover,
    fullAddress: getFullAddress(data.city, data.address),
    ...getFormattedMarket(data.market_place)
  };
};

export const getFormattedMarket = (data: MarketplaceResponse): Marketplace => {
  return {
    marketId: data.id,
    offer: data?.offer,
    conditions: data?.conditions,
    sections: getFormattedMarketSections(data.sections),
    contacts: {
      phone: data.phone,
      whatsapp: data?.whatsapp_link,
      telegram: data?.tg_link
    }
  };
};

const getFormattedMarketSections = (sections): MarketplaceSection[] => {
  return sections?.map(section => ({
    sectionId: section.id,
    title: section.title,
    categories: getFormattedMarketCategories(section.categories)
  }));
};

const getFormattedMarketCategories = (categories): MarketplaceCategory[] => {
  return categories?.map(category => ({
    categoryId: category.id,
    section: category.section,
    title: category.title,
    description: category.description,
    image: category?.thumbnail_image
  }));
};

export const getFormattedCategory = (data: CategoryResponse): Category => {
  return {
    id: data.id,
    title: data.title,
    description: data.description,
    coverImage: data.cover_image,
    categoryType: data.category_type,
    products: data.products?.map(p => ({
      id: p.id,
      title: p.title,
      description: p.description,
      image: p?.thumbnail_image,
      productType: p.product_type,
      price: p.selling_price
    }))
  };
};

export const getFormattedProduct = (data: ProductResponse): Product => {
  return {
    id: data.id,
    title: data.title,
    description: data.description,
    image: data?.cover_image,
    price: data.selling_price,
    productType: data.product_type,
    categories: data.categories
  };
};
