import React, {useCallback} from "react";
import {push} from "redux-first-history";
import {useParams} from "react-router";
import {useAppDispatch} from "store";
import {getFormatRub} from "utils/currency";

import {clsx} from "clsx";
import styles from "./styles.module.scss";

interface DefaultBottomProps {
  loadedCart: boolean;
  totalCost: number;
  itemsCount?: number;
}

const DefaultBottom: React.FC<DefaultBottomProps> = React.memo(({loadedCart, itemsCount, totalCost}) => {
  const {slug} = useParams();
  const dispatch = useAppDispatch();

  const openCart = useCallback(() => {
    if (!loadedCart) {return;}
    dispatch(push(`/${slug}/cart`));
  }, [loadedCart, dispatch]);

  return (
    <div className={clsx(styles.bottomInner, {[styles.bottomInnerLoading]: !loadedCart})}>
      <div className={styles.bottomButtonInfo}>
        <div className={styles.bottomButtonTotal}><span>Сумма заказа:</span><span>{getFormatRub(totalCost)}</span></div>
        <div className={styles.bottomButtonAmount}><span>Товаров в заказе:</span><span>{itemsCount}</span></div>
      </div>
      <div className={styles.bottomButtonOrder} onClick={openCart}>В корзину</div>
    </div>
  );
});

export default DefaultBottom;
