import {createSlice, current}                from '@reduxjs/toolkit';
import {
  getObjectInfo, getCategoryInfo,
  getProductInfo, getCart, addProduct,
  clearCart, deleteProduct, deleteItemProduct,
  startPayment, initMarket
} from "./thunk";
import extend, {baseState}                    from "utils/redux/extend";
import {ACTION_TYPE, PREFIX} from "./consts";
import {addItem, getCategories, getRequestKey} from "pages/market/helpers";

import type {
  Marketplace,
  MarketplaceCategory,
  MarketplaceSection
}                                   from "pages/market/Model/Marketplace";
import type {Category}              from "pages/market/Model/Category";
import type {Product}               from "pages/market/Model/Product";
import type {Cart}                  from "pages/market/Model/Cart";
import type {BaseState}             from "utils/redux/Model";

export interface MarketState extends BaseState {
  loaded: boolean;
  loadedCategory: boolean;
  loadedProduct: boolean;
  loadedCart: boolean;
  loadedCartPayment: boolean;
  submitting: boolean;
  info: Omit<Marketplace, 'marketId' | 'sections'> | null;
  categories: MarketplaceCategory[] | null;
  sections: MarketplaceSection[] | null;
  objectName: string;
  fullAddress: string;
  image?: string;
  marketId?: number;
  categoryId?: number;
  productId?: number;
  category?: Category;
  product?: Product;
  cart: Cart | null;
  pendingRequests: {
    add: {key: string, quantity: number}[];
    delete: {key: string, quantity: number}[];
  };
  pendingRequest: {key: string; amount: number} | null;
  openedConditions: boolean;
  name?: string;
  phone?: string;
  agreement: boolean;
  paymentMethod: string | null;
  sid: string | null;
}

const initialState: MarketState = {
  loaded: true,
  loadedCategory: true,
  loadedProduct: true,
  loadedCart: true,
  loadedCartPayment: true,
  sid: null,
  info: null,
  sections: null,
  categories: null,
  objectName: '',
  fullAddress: '',
  cart: null,
  pendingRequests: {
    add: [],
    delete: []
  },
  pendingRequest: null,
  openedConditions: false,
  agreement: false,
  paymentMethod: null,
  ...baseState
};

const slice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {
    clearRequest: (state) => {
      state.pendingRequest = null;
    },
    changeRequest: (state, {payload}) => {
      if (!payload?.key || !payload?.amount) {return;}
      state.pendingRequest = {key: payload.key, amount: payload.amount};
    },
    addPendingRequest: (state, {payload}) => {
      const currentState = current(state).pendingRequests;
      const key = getRequestKey(payload.categoryId, payload.productId);

      if (payload.action === ACTION_TYPE.ADD) {
        state.pendingRequests.add = addItem(key, currentState.add);
      }
      if (payload.action === ACTION_TYPE.DELETE) {
        state.pendingRequests.delete = addItem(key, currentState.delete);
      }
    },
    clearPendingRequest: (state, {payload}) => {
      const items = current(state).pendingRequests;
      const key = getRequestKey(payload.categoryId, payload.productId);
      const item = items[payload.action].find(it => it.key === key);

      if (payload.action === ACTION_TYPE.ADD && item) {
        state.pendingRequests.add = items.add.filter(it => it.key !== key);
      }
      if (payload.action === ACTION_TYPE.DELETE && item) {
        state.pendingRequests.delete = items.delete.filter(it => it.key !== key);
      }
    },
    setOpenedConditions: (state: MarketState, {payload}) => {
      state.openedConditions = payload;
    },
    setPaymentMethod: (state: MarketState, {payload}) => {
      state.paymentMethod = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(initMarket.fulfilled, (state: MarketState) => {
      state.loaded = true;
    });
    builder.addCase(initMarket.pending, (state: MarketState) => {
      state.loaded = false;
    });
    builder.addCase(initMarket.rejected, (state: MarketState) => {
      state.loaded = true;
    });
    builder.addCase(getObjectInfo.fulfilled, (state: MarketState, {payload}) => {
      state.info = {
        offer: payload?.offer,
        conditions: payload?.conditions,
        contacts: {
          telegram: payload.contacts?.telegram,
          whatsapp: payload.contacts?.whatsapp,
          phone:  payload.contacts?.phone
        }
      };
      state.marketId = payload.marketId;
      state.image = payload.image;
      state.fullAddress = payload.fullAddress;
      state.objectName = payload.name;
      state.sections = payload.sections;
      state.categories = getCategories(payload?.sections);
      state.sid = payload.sid;
    });
    builder.addCase(getCategoryInfo.fulfilled, (state: MarketState, {payload}) => {
      state.category = payload;
      state.categoryId = payload.id;
      state.loadedCategory = true;
    });
    builder.addCase(getCategoryInfo.pending, (state: MarketState) => {
      state.loadedCategory = false;
    });
    builder.addCase(getCategoryInfo.rejected, (state: MarketState) => {
      state.loadedCategory = true;
    });
    builder.addCase(getProductInfo.fulfilled, (state: MarketState, {payload}) => {
      state.product = payload;
      state.productId = payload.id;
      state.loadedProduct = true;
    });
    builder.addCase(getProductInfo.pending, (state: MarketState) => {
      state.loadedProduct = false;
    });
    builder.addCase(getProductInfo.rejected, (state: MarketState) => {
      state.loadedProduct = true;
    });
    builder.addCase(getCart.fulfilled, (state: MarketState, {payload}) => {
      state.cart = payload;
      state.loadedCartPayment = true;
    });
    builder.addCase(getCart.pending, (state: MarketState) => {
      state.loadedCartPayment = false;
    });
    builder.addCase(getCart.rejected, (state: MarketState) => {
      state.loadedCartPayment = true;
    });
    builder.addCase(addProduct.fulfilled, (state: MarketState, {payload}) => {
      state.cart = payload;
      state.sid = payload.sid;
      state.loadedCart = true;
    });
    builder.addCase(addProduct.pending, (state: MarketState) => {
      state.loadedCart = false;
    });
    builder.addCase(addProduct.rejected, (state: MarketState) => {
      state.loadedCart = true;
    });
    builder.addCase(deleteProduct.fulfilled, (state: MarketState, {payload}) => {
      state.cart = payload;
      state.loadedCart = true;
    });
    builder.addCase(deleteProduct.pending, (state: MarketState) => {
      state.loadedCart = false;
    });
    builder.addCase(deleteProduct.rejected, (state: MarketState) => {
      state.loadedCart = true;
    });
    builder.addCase(deleteItemProduct.fulfilled, (state: MarketState, {payload}) => {
      state.cart = payload;
      state.loadedCart = true;
    });
    builder.addCase(deleteItemProduct.pending, (state: MarketState) => {
      state.loadedCart = false;
    });
    builder.addCase(deleteItemProduct.rejected, (state: MarketState) => {
      state.loadedCart = true;
    });
    builder.addCase(clearCart.fulfilled, (state: MarketState) => {
      state.cart = null;
    });
    builder.addCase(startPayment.fulfilled, (state: MarketState) => {
      state.submitting = false;
    });
    builder.addCase(startPayment.pending, (state: MarketState) => {
      state.submitting = true;
    });
    builder.addCase(startPayment.rejected, (state: MarketState) => {
      state.submitting = false;
    });
  }
});

export const {
  setOpenedConditions,
  setPaymentMethod,
  addPendingRequest,
  clearPendingRequest,
  clearRequest,
  changeRequest
} = slice.actions;

export default extend<MarketState>(slice);
