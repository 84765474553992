import React, {useCallback} from "react";
import {useSelector} from "react-redux";
import {useAppDispatch} from "store";
import {setOpenedConditions} from "store/market/slice";
import {Icon} from "components/Icon";
import {BottomSheet} from "components/BottomSheet";

import styles from "./styles.module.scss";

import type {RootState} from "store";

const SupportContancts = () => {
  const model = useSelector((s: RootState) => s.market);
  const dispatch = useAppDispatch();

  const openConditions = useCallback(() => {
    dispatch(setOpenedConditions(true));
  }, []);

  const closeConditions = useCallback(() => {
    dispatch(setOpenedConditions(false));
  }, []);

  return (
    <>
      <div className={styles.support}>
        <div className={styles.label}>Мы рядом и готовы помочь</div>
        <div className={styles.social}>
          <a href={model?.info?.contacts?.telegram} target="_blank" rel="noreferrer"><div><Icon.Telegram /></div></a>
          <a href={model?.info?.contacts?.whatsapp} target="_blank" rel="noreferrer"><div><Icon.Whatsapp /></div></a>
        </div>
        <div className={styles.links}>
          <a href={model?.info?.offer} target="_blank" rel="noreferrer">Договор оферты</a>
          <a onClick={openConditions}>Условия пользования сервисом</a>
        </div>
      </div>
        <BottomSheet
          isOpen={model.openedConditions}
          handleClose={closeConditions}
          title="Условия пользования сервисом"
        >
          <div className={styles.conditions}>{model?.info?.conditions}</div>
        </BottomSheet>
    </>
  );
};

export default React.memo(SupportContancts);
