import React                            from "react";
import {useSelector}                    from "react-redux";
import {BOTTOM_TYPE}                    from "./consts";
import {getAmountProduct}               from "pages/market/helpers";
import CartBottom                       from "./CartBottom";
import DefaultBottom                    from "./DefaultBottom";
import ConfirmBottom                    from "./ConfirmBottom";
import ProductBottom                    from "./ProductBottom";

import styles                           from "./styles.module.scss";

import type {RootState}                 from "store";

interface BottomProps {
  type: string | null
}

const Bottom: React.FC<BottomProps> = ({type}) => {
  const model = useSelector((s: RootState) => s.market);
  const {product, category, cart, loadedCart} = model;
  const productId = product?.id;
  const categoryId = category?.id;

  const amount = productId && categoryId && getAmountProduct(categoryId, productId, model) || 0;

  const contentMap = {
    [BOTTOM_TYPE.CONFIRM]: <ConfirmBottom />,
    [BOTTOM_TYPE.CART]: <CartBottom loadedCart={loadedCart} isEmpty={!cart?.products?.length} />,
    [BOTTOM_TYPE.PRODUCT]: <ProductBottom
      productId={productId || 0}
      categoryId={categoryId || 0}
      productType={product?.productType || ''}
      cartAmount={amount}
      loadedCart={loadedCart}
    />,
    [BOTTOM_TYPE.DEFAULT_BOTTOM]: <DefaultBottom
      loadedCart={loadedCart}
      totalCost={model.cart?.totalCost || 0}
      itemsCount={model.cart?.itemsCount}
    />
  };

  if (!type) {return null;}

  return (
    <div className={styles.bottom}>
      {contentMap[type]}
    </div>
  );
};

export default React.memo(Bottom);

