import React, {memo, useEffect} from "react";
import {useSelector}            from "react-redux";
import {Description}            from "components/Description";
import {log}                    from "utils/analytics";
import {LOG_TYPES}              from "utils/consts";

import styles                   from "./styles.module.scss";

import type {RootState}         from "store";
import type {Template}          from "components/Description";

export const About: React.FC = memo(() => {
  const {parkingInfo} = useSelector((s: RootState) => s.parking);

  const TEMPLATE: Template[] = [];

  if (parkingInfo?.payment_data?.tariff?.description) {
    TEMPLATE.push(
      {
        title: 'Порядок оказания услуги',
        text: parkingInfo?.payment_data?.tariff?.description
      }
    );
  }
  if (parkingInfo?.payment_data?.tariff?.title) {
    TEMPLATE.push(
      {
        title: 'Тариф',
        text: parkingInfo?.payment_data?.tariff?.title
      }
    );
  }


  useEffect(() => {
    log(LOG_TYPES.SUBSCRIPTION_MODAL_OPEN, null, {
      SubscriptionID: parkingInfo?.subscription?.id || null,
      SubscriptionName: parkingInfo?.subscription?.title || null,
      ActivationDate: parkingInfo?.subscription?.activation_date || null,
      ExpirationDate: parkingInfo?.subscription?.expiration_date || null,
      CarNumber: parkingInfo?.car_number || null
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <Description template={TEMPLATE} />
    </div>
  );
});
