import {getFormatRub}           from "utils/currency";
import {formatPhone}            from "utils/phone";

import type {
  FormattedPaymentInfo,
  PaymentInfo
}                               from "pages/order-status/Model/PaymentInfo";

export const getFormattedPaymentInfo = (data: PaymentInfo): FormattedPaymentInfo => {
  const products = data.order_data.ordered_product_items?.map(it => ({
    id: it.id,
    product: {
      id: it.product.id,
      title: it.product.title,
      productType: it.product.product_type,
      clientPrice: it.product.price_for_client,
      discountedPrice: it.product.discount.amount,
      totalCost: getFormatRub(it.product.product_total_cost)
    },
    quantity: it?.quantity
  }));

  const discount = data.payment_data?.invoice?.discount_amount;

  return {
    order: {
      orderId: data.human_order_id,
      status: {name: data?.status?.external_name, systemName: data?.status?.system_name},
      paymentMethod: data?.payment_data?.payment_method,
      paymentType: data?.payment_data?.payment_type
    },
    invoice: {
      status: {name: data?.status?.external_name, systemName: data?.status?.system_name},
      amount: getFormatRub(data.payment_data?.invoice?.amount),
      discountAmount: discount && discount !== 0 ? getFormatRub(discount) : undefined
    },
    client: {
      name: data?.client_data?.name,
      phone: formatPhone(data?.client_data?.phone),
      email: data?.client_data?.email?.length ? data.client_data.email : undefined
    },
    products,
    success: data?.success
  };
};
