import {api} from "services";

import type {CartResponse} from "pages/market/Model/Cart";

export async function getObject(slug: string) {
  return api.get(`open/property-object/item/${slug}/`);
}

export async function getCategory(id: number | string) {
  return api.get(`open/property-object/product-category/item/${id}/`);
}

export async function getProduct(id: number | string) {
  return api.get(`open/property-object/product/item/${id}/`);
}

const MARKET_URL = 'open/marketplace/cart/';

export async function addProduct(
  productId: string | number,
  categoryId: string | number,
  marketId: number,
  quantity: number,
  sid: string | null
) {
  const data: {
    product_item_id: string | number;
    category: string | number;
    market_id: number;
    quantity: number;
    action_type: string;
    sid?: string | null
  } = {
    product_item_id: productId,
    category: categoryId,
    quantity,
    action_type: 'add',
    market_id: marketId
  };

  if (sid) {
    data.sid = sid;
  }
  return api.post<CartResponse>(MARKET_URL, data);
}

export async function deleteProduct(
  productId: string | number,
  categoryId: string | number,
  marketId: number,
  quantity: number,
  sid: string | null
) {
  const data: {
    product_item_id: string | number;
    category: string | number;
    market_id: number;
    quantity: number;
    action_type: string;
    sid?: string | null
  } = {
    product_item_id: productId,
    category: categoryId,
    quantity,
    action_type: 'remove',
    market_id: marketId
  };

  if (sid) {
    data.sid = sid;
  }

  return api.delete<CartResponse>(MARKET_URL, {data});
}

export async function clearCart(sid: string, marketId?: number) {
  const data = {sid, market_id: marketId, action_type: 'clear'};
  return api.delete(MARKET_URL, {data});
}

export async function getCart(sid: number | string, check?: boolean) {
  const params = {SID: sid, CheckOut: check};
  return api.get(MARKET_URL, {params});
}

export async function initPayment(
  entityId: string,
  paymentType: string,
  paymentMethod: string,
  firstName: string,
  phone?: string,
  email?: string,
  successUrl?: string,
  failUrl?: string
) {
  const data = {
    entity_id: entityId,
    product_type: 'cart',
    success_url: successUrl,
    fail_url: failUrl,
    payment_type: paymentType,
    payment_method: paymentMethod,
    first_name: firstName,
    email,
    phone
  };
  return api.post("billing/payment/init/", data);
}
