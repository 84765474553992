import React, {memo}  from "react";
import {STATUSES}     from "pages/home/consts";

import styles         from "./styles.module.scss";

import type {Dayjs}   from "dayjs";

interface Props {
  state: number | null;
  time?: Dayjs;
}

export const DeliveryInfo: React.FC<Props> = memo(({state, time}) => {

  const status = STATUSES[(state || 1) - 1];
  const offset = time?.utcOffset(0).format('HH:mm');

  return (
    <div className={styles.wrapper}>
      <div className={styles.delivery}>
        <div className={styles.item}>
          <p className={styles.title}>Время подачи авто:</p>
          <p className={styles.time}>{offset}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.title}>Статус подачи</p>
          <p className={styles.status}>{status}</p>
        </div>
      </div>
    </div>
  );
});
