import {getDateByTime, getDateNow} from "utils/date";

export interface StaffItem {
  staff: number;
  datetime: Date;
  sorting: string[];
}

export interface PromiseItem {
  config: {
    url: string;
  }
  data: {
    data: {
      seances: Seance[];
    } & Seance[];
  }
}

export interface Seance {
  datetime: Date;
  seance_length: number;
}

export function formatPromiseItems(items: PromiseItem[]): StaffItem[] {
  if (items?.length) {
    const values: StaffItem[] = [];
    items.forEach((res) => {
      if (res?.data?.data?.seances?.length) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
        const [_, __, staffId] = res.config.url.split('/');

        res?.data?.data?.seances.forEach((seance: Seance) => {
          const day = getDateByTime(seance.datetime).format('D');
          const time = getDateByTime(seance.datetime).format('HHmm');

          values.push({
            staff: Number(staffId),
            datetime: seance.datetime,
            sorting: [day, time]
          });
        });
      } else if (res?.data?.data?.length) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
        const [_, __, staffId] = res.config.url.split('/');

        res?.data?.data.forEach((seance: Seance) => {
          const day = getDateByTime(seance.datetime).format('D');
          const time = getDateByTime(seance.datetime).format('HHmm');

          values.push({
            staff: Number(staffId),
            datetime: seance.datetime,
            sorting: [day, time]
          });
        });
      }
    });

    return sortStaffDates(values);
  } else {
    return [];
  }
}

export function sortStaffDates(items: StaffItem[]): StaffItem[] {
  const date = getDateNow();
  const day = date.format('DD');
  const time = date.format('HHmm');

  if (items?.length) {
    return items
      ?.filter(seance => {
        if (seance.sorting[0] > day) {
          return seance;
        } else if (seance.sorting[0] === day && seance.sorting[1] >= time) {
          return seance;
        }
      })
      .sort((a, b) => {
      const [aDay, aTime] = a.sorting;
      const [bDay, bTime] = b.sorting;
      if (aDay >= bDay && aTime > bTime) {
        return 1;
      } else if (aDay > bDay && aTime < bTime) {
        return 1;
      } else if (aDay === bDay && aTime < bTime) {
        return -1;
      } else if (aDay < bDay && aTime === bTime) {
        return -1;
      } else if (aDay < bDay && aTime < bTime) {
        return -1;
      } else {
        return 0;
      }
    });
  } else {
    return [];
  }
}
