import {api} from "services";

import type {PaymentInfo} from "pages/order-status/Model/PaymentInfo";

export async function getStatus({orderId}: {
  orderId: string;
}) {
  return api.get<PaymentInfo>('billing/check-status', {
    params: {orderId}
  });
}

export async function retryPayment(orderId: string) {
  const data = {order: orderId, product_type: "order"};
  return api.post("billing/payment/init/", data);
}
