import React, {useCallback, useEffect}  from "react";
import {useAppDispatch}                 from "store";
import {useSearchParams}                from "react-router-dom";
import {useSelector}                    from "react-redux";
import {getPaymentInfo, openPayment}    from "store/orderStatus/thunk";
import StatusInfo                       from "./components/StatusInfo";
import StatusProductList                from "./components/StatusProductList";
import {Loader}                         from "components/Loader";

import type {RootState}                 from "store";

import styles                           from "./styles.module.scss";

function OrderStatus() {
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();

  const {loaded, hasBottomButton, submitting} = useSelector((state: RootState) => state.orderStatus);

  const orderId = params.get("orderId");
  const isPaymentFailed = params.get("paymentStatus") === "false";

  // useMarketInitialization(false);

  useEffect(() => {
    dispatch(getPaymentInfo({orderId, isSuccess: !isPaymentFailed}));
  }, []);

  const retryPayment = useCallback(() => {
    if (submitting && orderId) {return;}
    dispatch(openPayment({orderId}));
  }, [submitting]);

  if (!loaded) {
    return <div className={styles.loader}><Loader /></div>;
  }

  return (
      <div className={styles.wrapper}>
        {isPaymentFailed && (
          <div className={styles.failInfo}>
            Сожалеем, что у вам не удалось оплатить ваш заказ. Вы можете повторить попытку или написать в поддержку, если проблема сохраняется.
          </div>
        )}
        <StatusInfo />
        <StatusProductList />
        {hasBottomButton && (
          <div className={styles.bottom}>
            <div className={styles.innerText} onClick={retryPayment}>
              Оплатить заказ
            </div>
          </div>
        )}
      </div>
  );
}

export default OrderStatus;
