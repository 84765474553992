import React from "react";
import {getBgImageStyles} from "pages/market/helpers";

import styles from "./styles.module.scss";

import type {MarketplaceCategory} from "pages/market/Model/Marketplace";

interface CategoryItemProps extends MarketplaceCategory {
  onClick: (id: number) => void;
}

const CategoryItem: React.FC<CategoryItemProps> = ({onClick, categoryId, title, image}) => {
  return (
    <div className={styles.item} style={getBgImageStyles(image, true)} onClick={() => onClick(categoryId)}>
      <div className={styles.itemLabel}>{title}</div>
    </div>
  );
};

export default CategoryItem;
