import React, {memo, useCallback, useMemo, useRef} from "react";
import {useNavigate} from "react-router";
import {useAppDispatch} from "store";
import {useHeaderObserver} from "utils/hooks/useHeaderObserver";
import {Icon} from "components/Icon";
import {getHeaderText, HeaderTextParams} from "pages/market/helpers";
import {MARKET_SCREEN} from "pages/market/consts";

import {clsx} from "clsx";
import styles from "./styles.module.scss";

interface HeaderProps {
  close?: () => void;
  loading?: boolean;
  screen: string;
  textParams: HeaderTextParams;
}

export const Header: React.FC<HeaderProps> = memo(({
  close,
  loading = false,
  screen,
  textParams
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  useHeaderObserver(headerRef);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isStartScreen = screen === MARKET_SCREEN.CATEGORIES || screen === MARKET_SCREEN.STATUS;

  const text = useMemo(() => getHeaderText(screen, textParams), [screen, textParams]);

  const onBack = useCallback(() => {
    navigate(-1);
  }, [screen, dispatch, navigate]);

  const renderContent = () => {
    if (loading) {
      return !isStartScreen ? (
        <div className={styles.loadingHeader}>
          <div className={styles.loadingSmallText} />
          <div className={styles.loadingTitle} />
        </div>
      ) : (
        <div className={styles.loadingHeader}>
          <div className={styles.loadingTitle} />
          <div className={styles.loadingText} />
        </div>
      );
    }

    return !isStartScreen ? (
      <>
        <div className={styles.smallText}>{text.description}</div>
        <div className={styles.title}>{text.title}</div>
      </>
    ) : (
      <>
        <div className={styles.title}>{text.title}</div>
        <div className={styles.text}>{text.description}</div>
      </>
    );
  };

  return (
    <div ref={headerRef} className={clsx(styles.top, {[styles.hasAction]: !isStartScreen || close})}>
      {!isStartScreen && <div className={styles.back} onClick={onBack}><Icon.Back /></div>}
      {renderContent()}
      {close && (<div className={styles.close} onClick={close}><Icon.Close /></div>)}
    </div>
  );
});
