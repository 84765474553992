import React                        from 'react';
import {useSelector}                from "react-redux";
import {Outlet}                     from 'react-router-dom';
// import {useAppDispatch}             from "store";
import MarketLayoutLoader           from "./views/components/MarketLayoutLoader";
import MarketLayout                 from "./views/components/MarketLayout";
import {useMarketInitialization}    from "./helpers";

import type {RootState}             from "store";

function Market() {
  const model = useSelector((s: RootState) => s.market);
  // const dispatch = useAppDispatch();
  useMarketInitialization();

  if (!model.loaded) {return <MarketLayoutLoader />;}

  return (
    <MarketLayout>
      <Outlet />
    </MarketLayout>
  );
}

export default React.memo(Market);
