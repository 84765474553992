export function getFormatNum(amount: string | number | undefined | null): string {
  let num = amount || 0;
  if (typeof num === "number") {
    num = String(num);
  }
  if (num?.includes('.')) {
    num = num.split('.')[0];
  }
  return new Intl.NumberFormat('ru-Ru').format(Number(num));
}

export function getFormatRub(amount: string | number | undefined | null): string {
  return `${getFormatNum(amount)} ₽`;
}
