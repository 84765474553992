import React, {useCallback, useContext} from "react";
import {formatPhone} from "./helpers";
import {Context} from "utils/redux/context";
import {useSelector} from "react-redux";
import {useAppDispatch} from "store";

import styles from "./styles.module.scss";

interface InputProps {
  name: string;
  placeholder?: string;
  prefix?: string;
  value?: string;
  onChange?: (name: string, val: string, event?: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (name: string, val: string) => void;
  onFocus?: (name: string, val: string) => void;
  type?: string;
  inputMode?: "numeric" | "text" | "search" | "email" | "tel" | "url" | "none" | "decimal";
  formatter?: (value: string) => string;
  error?: string | null;
  maxLength?: number;
  label?: string;
}

const Input: React.FC<InputProps> = ({
  name,
  placeholder,
  value,
  prefix,
  onChange,
  onBlur,
  onFocus,
  inputMode = "text",
  error,
  maxLength
}) => {
  const dispatch = useAppDispatch();
  const context = useContext(Context);
  const model = prefix as string || context?.prefix;
  const actions = context?.actions;

  const fieldValue = useSelector(s => s?.[model]?.[name]) || value;
  const fieldError = useSelector(s => s?.[model]?.['errors']?.[name]) || error || null;

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (inputMode === 'tel') {
      val = formatPhone(e.target.value);
    }

    onChange && onChange(name, val, e);
    actions?.handleChange && dispatch(actions.handleChange(name, val));
  }, [onChange, name, inputMode, actions]);

  const handleBlur = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onBlur && onBlur(name, e.target.value);
    actions?.handleBlur && dispatch(actions.handleBlur(name, e.target.value));
  }, [onBlur, actions, name]);

  const handleFocus = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onFocus && onFocus(name, e.target.value);
  }, [name]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (inputMode === 'tel') {
      const inputValue = fieldValue?.replace(/\D/g, '');
      if (e.key == 'Backspace' && inputValue?.length == 1) {
        onChange && onChange(name, "");
        actions?.handleChange && dispatch(actions.handleChange(name, ""));
      }
    }
  }, [onChange, name, inputMode, actions, fieldValue]);

  return (
    <div className={styles.inputContainer}>
      <input
        className={styles.input}
        type="text"
        inputMode={inputMode}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        maxLength={maxLength}
      />
      {!!fieldError?.length && <div className={styles.error}>{fieldError}</div>}
    </div>
  );
};

export default React.memo(Input);
