import {MARKET_SCREEN}    from "pages/market/consts";
import {BOTTOM_TYPE}      from "./consts";

import type {Cart}        from "pages/market/Model/Cart";

export const getBottomType = (screen: string, cart: Cart | null, loadedProduct: boolean): string | null => {
  const hasCartItems = cart && cart?.products?.length > 0;
  if (screen === MARKET_SCREEN.CONFIRMATION && hasCartItems) {
    return BOTTOM_TYPE.CONFIRM;
  } else if (screen === MARKET_SCREEN.PRODUCT && loadedProduct) {
    return BOTTOM_TYPE.PRODUCT;
  } else if (screen === MARKET_SCREEN.CART) {
    return BOTTOM_TYPE.CART;
  } else if ((screen === MARKET_SCREEN.PRODUCTS || screen === MARKET_SCREEN.CATEGORIES) && hasCartItems) {
    return BOTTOM_TYPE.DEFAULT_BOTTOM;
  }
  return null;
};

export const getProductBottomTitle = (cartAmount: number, currentAmount: number): string => {
  if (cartAmount === 0 && currentAmount !== 0) {
    return 'Добавить';
  } else if (cartAmount > 0 && currentAmount !== 0 && cartAmount !== currentAmount) {
    return 'Сохранить';
  } else if (currentAmount === 0 && cartAmount > 0) {
    return 'Удалить';
  } else {
    return 'Готово';
  }
};
