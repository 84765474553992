import React, {useMemo, memo, useEffect}  from 'react';
import {useSelector}                      from "react-redux";
import {useAppDispatch}                   from "store";
import {CHECK_STATUS_REQUEST}             from "utils/consts";
import {differenceInTime}                 from "utils/date";
import {checkStatusRequest}               from "store/parking/thunk";
import {Bar}                              from 'components/ProgressBar/Bar';
import getPercentFromTimes                from "utils/getPercentFromTimes";

import type {RootState}                   from "store";

const getLabel = (state: number | null, percent: number, timeDone: number[]): string => {
  if (state === 7) {return 'Авто ожидает';}
  if (percent >= 100) {return 'Уже спешим';}

  const [, , days, hours, minutes] = timeDone;
  const parts: string[] = [];
  if (days > 0) {parts.push(`${days} д`);}
  if (hours > 0) {parts.push(`${hours} ч`);}
  if (minutes > 0) {parts.push(`${minutes} мин`);}

  return parts.length > 0 ? `Осталось: ${parts.join(' ')}` : 'Осталось совсем немного';
};

export const DeliveryProgress = memo(() => {
  const {watchStatus, deliveryTime, parkingInfo, startedTime, state, currentTime} = useSelector((s: RootState) => s.parking);
  const dispatch = useAppDispatch();
  const createdAt = parkingInfo?.request?.created_at;

  useEffect(() => {
    if (watchStatus && parkingInfo?.id) {
      const statusWatchInterval = setInterval(() => {
        dispatch(checkStatusRequest(parkingInfo.id));
      }, CHECK_STATUS_REQUEST);

      return () => {
        clearInterval(statusWatchInterval);
      };
    }
  }, [watchStatus]);

  if (!deliveryTime || !startedTime || !createdAt || state === 8) {
    return null;
  }

  const percent = getPercentFromTimes(
    parkingInfo.request.created_at,
    deliveryTime.subtract(3, 'hours'),
    currentTime
  );

  const timeDone = useMemo(() => {
    return differenceInTime(
      currentTime.subtract(59, "seconds").add(deliveryTime.utcOffset(), 'm'),
      deliveryTime
    ) as number[];
  }, [currentTime, deliveryTime]);

  const isFullPercents = state === 8 || state === 7;
  const label = useMemo(() => getLabel(state, percent, timeDone), [state, percent, timeDone]);

  return (
    <Bar
      label={label}
      percent={isFullPercents ? 100 : percent}
    />
  );
});
