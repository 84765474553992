import React, {useCallback, useEffect} from "react";
import {push} from "redux-first-history";
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import {useAppDispatch} from "store";
import EmptyProducts from "./EmptyProducts";
import {getCategoryInfo} from "store/market/thunk";
import {getAmountProduct} from "pages/market/helpers";
import ProductItem from "./ProductItem";

import styles from "./styles.module.scss";

import type {RootState} from "store";

const Category: React.FC = () => {
  const {slug, categoryId} = useParams();
  const model = useSelector((s: RootState) => s.market);
  const {category, loadedCategory, categoryId: apiCategoryId} = model;
  const dispatch = useAppDispatch();

  const handleOpenProduct = useCallback((productId: number) => {
    dispatch(push(`/${slug}/${categoryId}/${productId}`));
  }, []);

  useEffect(() => {
    if (categoryId && categoryId !== String(apiCategoryId)) {
      dispatch(getCategoryInfo(categoryId));
    }
  }, []);

  if (!loadedCategory) {return <EmptyProducts />;}
  if (!category) {return null;}

  return (
    <div className={styles.wrapper}>
      <div className={styles.items}>
        {category.products.map((it, i: number) => {
          if (!category) {return null;}
          const amount = getAmountProduct(category.id, it.id, model) || 0;

          return (
            <ProductItem
              {...it}
              categoryId={category.id}
              productId={it.id}
              key={i}
              amount={amount}
              openProductItem={handleOpenProduct}
              productType={it.productType}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Category;
