import {configureStore} from '@reduxjs/toolkit';
import {useDispatch}    from "react-redux";
import {createReduxHistoryContext} from "redux-first-history";
import {createBrowserHistory} from 'history';

import parking          from "store/parking/slice";
import service          from "store/service/slice";
import modules          from "store/modules/slice";
import payment          from "store/payment/slice";
import market           from "store/market/slice";
import orderStatus      from "store/orderStatus/slice";

const {createReduxHistory, routerMiddleware, routerReducer} = createReduxHistoryContext({
  history: createBrowserHistory()
});

export const store = configureStore({
  reducer: {
    router: routerReducer,
    parking,
    service,
    modules,
    payment,
    market,
    orderStatus
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).concat(routerMiddleware),
  devTools: process.env.NODE_ENV !== 'production'
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const browserHistory = createReduxHistory(store);
